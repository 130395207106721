import { ContentHubTable } from "../ContentHubTable/ContentHubTable";
import {
  ContentHubListRegistry,
  ContentHubSiteRegistry,
} from "../../Sites/SitesRegistry";
import { Column } from "ka-table/models";
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import useContentHubList from "../../../Hooks/useContentHubList";
import { ContentHubItemElement } from "../../../Services/types/ContentHub/ContentHubItemElement";
import { SelectedContentHubListContext } from "../../../Context/SelectedContentHubListContext";
import { ContentHubContext } from "../../../Context/ContentHubContext";
export default function ContentHubList<T = unknown>(props: {
  siteRegistry: ContentHubSiteRegistry;
  listRegistry: ContentHubListRegistry;
  openForm?: boolean;
  onFormStateChanged?: (state: boolean) => void;
}) {
  const contentHubContext = useContext(ContentHubContext);
  const [itemsPerPage, setItemsPerPage] = useState<number>(25);
  const {
    listDefinition,
    items,
    isLoading,
    hasMoreItems,
    ClearItems,
    LoadMoreItems,
    SearchItems,
  } = useContentHubList(
    props.siteRegistry.id!,
    props.listRegistry.id!,
    undefined,
    itemsPerPage
  );
  useEffect(() => {
    if (listDefinition !== undefined) {
      LoadMoreItems();
    }
  }, [LoadMoreItems, listDefinition]);

  const [tableColumns, setTableColumns] = useState<Column[]>([]);

  const [mappedItems, dispatchMappedItems] = useReducer(() => {
    return items.map((i) => props.listRegistry.mapper(i));
  }, []);

  useEffect(() => dispatchMappedItems(), [items, listDefinition]);

  const [canDeleteItems, setCanDeleteItems] = useState<boolean>(false);
  useEffect(() => {
    if (props.listRegistry.id && props.siteRegistry.id) {
      const index = contentHubContext.permissions.findIndex(
        (p) =>
          p == "ContentHub.Admin" ||
          p == "Items.Manage" ||
          p == `${props.siteRegistry.id}/Items.Manage` ||
          p == `${props.siteRegistry.id}/${props.listRegistry}/Items.Manage`
      );
      setCanDeleteItems(index >= 0);
    } else {
      setCanDeleteItems(false);
    }
    if (props.listRegistry?.columns) {
      setTableColumns(
        props.listRegistry.columns
          .map(
            (c) =>
              ({
                key: c.key,
                title: c.displayName,
                visible: c.visible ?? true,
                style: c.style,
              } as Column)
          )
          .concat([
            {
              key: "menuClick",
              style: { textAlign: "center", padding: 4 },
              width: 50,
            },
          ])
      );
    }
  }, [props.listRegistry, props.siteRegistry]);

  const [selectedContentHub, , , setSelectedContentHubItem] = useContext(
    SelectedContentHubListContext
  );

  //Drawer Methods
  const [selectedItem, setSelectedItem] =
    useState<ContentHubItemElement<T> | null>();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const openDrawer = useCallback(
    (itemSelected: { id: string } | null) => {
      if (itemSelected && selectedItem?.id !== itemSelected?.id) {
        const selected = items.find((item) => item.id === itemSelected.id);
        if (
          selected !== undefined &&
          (selectedContentHub.selectedItem !== selected.id ||
            drawerOpen == false)
        ) {
          if (selectedContentHub.selectedItem !== selected.id) {
            setSelectedContentHubItem(selected.id);
          }
          if (selectedItem?.id != selected?.id) {
            setSelectedItem(selected as ContentHubItemElement<T>);
          }
          setDrawerOpen(true);
          props.onFormStateChanged?.(true);
        }
      } else {
        setSelectedContentHubItem(null);
        setDrawerOpen(true);
        props.onFormStateChanged?.(true);
      }
    },
    [
      items,
      props,
      selectedContentHub.selectedItem,
      selectedItem?.id,
      setSelectedContentHubItem,
    ]
  );

  const closeDrawer = useCallback(
    (_state: unknown | null, changesApplied: boolean) => {
      setSelectedItem(null);
      setDrawerOpen(false);
      setSelectedContentHubItem(null);
      if (changesApplied) {
        ClearItems();
      }
      props.onFormStateChanged?.(false);
    },
    [ClearItems, setSelectedContentHubItem]
  );

  useEffect(() => {
    if (selectedContentHub.selectedItem && !isLoading && !drawerOpen) {
      openDrawer({ id: selectedContentHub.selectedItem });
    }
  }, [selectedContentHub, isLoading]);

  useEffect(() => {
    if (props.openForm === undefined) return;
    if (drawerOpen !== props.openForm) {
      setDrawerOpen(props.openForm);
    }
  }, [props.openForm, drawerOpen]);

  return (
    <div className="flexfill nooverflow">
      {listDefinition && (
        <>
          <div className="flexfill nooverflow">
            <ContentHubTable
              onItemClicked={(v) => openDrawer(v as { id: string })}
              onNext={() => LoadMoreItems()}
              itemsPerPageChanged={(value) => setItemsPerPage(value)}
              searchTrigger={SearchItems}
              hasMoreItems={hasMoreItems}
              isLoading={isLoading}
              showEndMessage={!hasMoreItems}
              list={listDefinition}
              canDeleteItems={canDeleteItems}
              dataItems={mappedItems}
              tableColumns={tableColumns}
            />
          </div>
          {props.listRegistry.renderForm(
            contentHubContext.sites.find(
              (s) => s.id === props.siteRegistry.id
            )!,
            listDefinition!,
            selectedItem,
            drawerOpen!,
            closeDrawer
          )}
        </>
      )}
    </div>
  );
}
