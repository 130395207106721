import { CSSProperties } from "react";
import { ContentHubItemElement } from "../../Services/types/ContentHub/ContentHubItemElement";
import { ContentHubListsElement } from "../../Services/types/ContentHub/ContentHubListsElement";
import { ContentHubSiteElement } from "../../Services/types/ContentHub/ContentHubSiteElement";
import { BadDebtListRegistry } from "./Credit/BadDebt/BadDebtRegistry";
import { BalanceTransferListRegistry } from "./Credit/BalanceTransfer/BalanceTransferRegistry";
import { SqlQueryRegistry } from "./IT/SqlQueryRequests/SqlQueryRequestRegistry";
export type ContentHubListRegistry = {
  id?: string;
  name: string;
  header: string;
  columns: ContentHubListColumn[];
  mapper(item: ContentHubItemElement): Record<string, unknown>;
  renderForm(
    siteDefinition: ContentHubSiteElement,
    listDefinition: ContentHubListsElement,
    selectedItem: ContentHubItemElement | null | undefined,
    drawerOpen: boolean,
    onDrawerClosed: (result: unknown | null, changesApplied: boolean) => void
  ): JSX.Element;
};
export type ContentHubSiteRegistry = {
  id?: string;
  name: string;
  text: string;
  lists: ContentHubListRegistry[];
};
export type ContentHubListColumn = {
  key: string;
  displayName: string;
  visible?: boolean;
  style?: CSSProperties;
};

export const ContentHubSitesRegistry: ContentHubSiteRegistry[] = [
  {
    name: "credit",
    text: "Credit",
    lists: [BadDebtListRegistry, BalanceTransferListRegistry],
  },
  {
    name: "it",
    text: "IT",
    lists: [SqlQueryRegistry],
  },
];
