import { object, string, ObjectSchema, array } from 'yup';

export interface ContentHubAttachments {
    itemFolderId?: string | null,
    filenames?: string[] | null
}

export const ContentHubAttachmentsSchema: ObjectSchema<ContentHubAttachments> = object({    
    itemFolderId: string().nullable(),
    filenames: array().of(string().defined()).nullable()
})