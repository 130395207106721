import { useContext, useEffect, useState } from "react";
import {
  ApprovalRequest,
  ApprovalStatus,
} from "../../Services/types/approvalRequest";
import {
  getApprovalHistory,
  getApprovalItem,
} from "../../Services/approvals/ApprovalService";
import { TeamsFxContext } from "../Context";
import { ApprovalCard } from "../ApprovalTab/ApprovalDrawer/ApprovalCard";
import { UserContext } from "../../Context/UserContext";
import { ApprovalHistoryState } from "../../Services/types/approvalHistoryElement";
import { Spinner } from "@fluentui/react-components";

export const ContentHubItemApprovals = ({
  siteId,
  listId,
  itemId,
  status,
  history,
  approvals,
}: {
  siteId: string;
  listId: string;
  itemId: string;
  status: ApprovalStatus;
  history?: ApprovalHistoryState[];
  approvals?: ApprovalRequest[];
}) => {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const [approvalItems, setApprovalItems] = useState<ApprovalRequest[]>(
    approvals ?? []
  );
  const [historyItems, setHistoryItems] = useState<ApprovalHistoryState[]>(
    history ?? []
  );
  useEffect(() => {
    setApprovalItems(approvals ?? []);
  }, [approvals]);
  useEffect(() => {
    setHistoryItems(history ?? []);
  }, [history]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const user = useContext(UserContext);
  useEffect(() => {
    if (!teamsUserCredential) return;
    if (history == undefined || approvals == undefined) {
      setIsLoading(true);
      (async () => {
        const approvalHistory = await getApprovalHistory(
          `${siteId}/${listId}/${itemId}`,
          teamsUserCredential
        );
        setHistoryItems(approvalHistory);

        const uniqueIds = new Set(approvalHistory.map((h) => h.requestId));
        const items: ApprovalRequest[] = [];
        const array: string[] = [];

        uniqueIds.forEach((v) => array.push(v));
        for (const id of array) {
          items.push(await getApprovalItem(id, teamsUserCredential));
          setApprovalItems(items);
        }
        setIsLoading(false);
      })();
    }
  }, [siteId, listId, itemId, status, teamsUserCredential]);
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      {approvalItems.map((r, i) => (
        <div key={i} style={{ flex: "1" }}>
          <ApprovalCard
            request={r}
            history={historyItems}
            userEmail={user.userInfo!.mail}
            collapsed={r.status !== ApprovalStatus.Pending}
            onUpdate={(response) => {
              setApprovalItems((a) => {
                const copy = [...a];
                copy[i] = response;
                return copy;
              });
            }}
            showLinks={false}
            showParameters={false}
            showSource={false}
          ></ApprovalCard>
        </div>
      ))}
      {isLoading && (
        <Spinner size="medium" label="Loading" style={{ padding: "6px 0" }} />
      )}
    </div>
  );
};
