import { Field, Label } from "@fluentui/react-components";
import { Control, FieldValues, useController, Path } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { getSelectStyle, getSelectTheme } from "../CommonSelect/CommonSelect";
import { useContext, useCallback, useState, useEffect } from "react";
import { TeamsFxContext } from "../../Context";
import { useDebouncedCallback } from "use-debounce";
import * as CounterpartyService from "../../../Services/general/CounterpartyService";
import { Counterparty } from "../../../Services/types/General/Counterparty";
import { SingleValue } from "react-select";

export const SelectCounterparty = <T extends FieldValues>({
  label,
  showInfo,
  defaultCounterparty,
  control,
  name,
  handleCounterpartySelection,
  required,
  disabled = undefined
}: {
  label: string;
  showInfo?: boolean | undefined;
  defaultCounterparty?: Counterparty | undefined;
  control: Control<T>;
  name: Path<T>;
  required?: boolean;
  handleCounterpartySelection: (counterparty: Counterparty) => void;
  disabled?: boolean
}) => {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { themeString } = useContext(TeamsFxContext);
  const { field } = useController({
    control,
    name,
  });

  const [requiredField, setRequiredField] = useState(required);

  useEffect(() => {
    setRequiredField(required);
  }, [required]);

  useEffect(()=>{
    if(field.value){
      searchCounterparties(field.value);
    }
  },[field])

  const searchCallback = useCallback(
    useDebouncedCallback((inputValue, callback) => {
      searchCounterparties(inputValue).then((options) => callback(options));
    }, 330),
    []
  );

  const [selectedCounterparty, setSelectedCounterparty] = useState<
    Counterparty | undefined
  >(defaultCounterparty);
  const [counterparties, setCounterparties] = useState<Counterparty[]>([]);
  const searchCounterparties = async (counterpartySearch: string) => {
    let items = counterparties;
    if (counterparties.length == 0) {
      const response = await CounterpartyService.getCounterparties(
        teamsUserCredential!
      );
      setCounterparties(response);
      items = response;
    }

    const filter = items.filter((counterparty) =>
      counterparty.name
        .toLocaleLowerCase()
        .includes(counterpartySearch.toLocaleLowerCase())
    );
    return filter;
  };

  const defaultValue: Counterparty = {
    name: "",
    sic: "",
    counterpartyClass: "",
    counterpartyId: 0,
    status: "",
  };
  const onChangeSelect = (data: SingleValue<Counterparty>) => {
    if (data !== null) {
      setSelectedCounterparty(data);
      handleCounterpartySelection(data);
    } else {
      setSelectedCounterparty(defaultValue);
      handleCounterpartySelection(defaultValue);
    }
    //setRequiredField(false);
  };

  return (
    <div>
      <Field
        label={label}
        required={requiredField !== undefined ? requiredField : false}
      >
        <AsyncSelect<Counterparty>
          isDisabled={disabled}
          cacheOptions={true}
          {...field}
          styles={getSelectStyle(themeString)}
          theme={getSelectTheme(themeString)}
          loadOptions={searchCallback}
          getOptionValue={(option) => `${option.name}`}
          getOptionLabel={(option) => `${option.name}`}
          onChange={onChangeSelect}
          value={counterparties.find((c) => c.name === field.value)}
          defaultValue={
            selectedCounterparty?.name !== "" ? selectedCounterparty : undefined
          }
          isClearable={true}
          noOptionsMessage={() => "Search for a Counter Party"}
        />
      </Field>
      {showInfo !== undefined &&
        showInfo &&
        selectedCounterparty !== undefined &&
        selectedCounterparty.name !== "" && (
          <div className="counterpartyInfoDiv">
            <div className="counterpartyIdDiv">
              <Label>
                Counterparty ID: {selectedCounterparty.counterpartyId}
              </Label>
            </div>

            <div className="counterpartySicDiv">
              <Label>SIC Code: {selectedCounterparty.sic}</Label>
            </div>
            <div className="counterpartyClassDiv">
              <Label>
                Class Type: {selectedCounterparty.counterpartyClass}
              </Label>
            </div>
          </div>
        )}
    </div>
  );
};
