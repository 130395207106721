import { dropDownToString } from "../../General/DropDownData";
import { counterpartyToSingleValue } from "../../General/Counterparty"
import { object, string, ObjectSchema, number } from 'yup';
import { ContentHubAttachments, ContentHubAttachmentsSchema } from "../ContentHubAttachments";
import { ContentHubUser, ContentHubUserSchema } from "../../General/user";

//export type BalanaceTransferReviewStatus = 'Pending' | 'Approved' | 'Revision Required';
export type BalanceTransferYesNo = 'Yes' | 'No';
export type BalanceTransferStatusOptions = 'Pending' | 'Ready for Credit Approval' | 'Ready for Billing Analyst' | 'Ready for Billing Manager' | 'Billing Manager Approved' | 'Completed' | 'Denied';

export interface BalanceTransfer {
    requestor: ContentHubUser,
    counterpartyFrom: string,
    accountNumberFrom: string,
    transactionAmountFrom: number,
    counterpartyTo: string,
    accountNumberTo: string,
    transactionAmountTo: number,
    requestorComments: string,
    status: string,
    clientApprovalAttached: BalanceTransferYesNo,
    creditDocumentationAttached: BalanceTransferYesNo,
    balanceTransferTemplateAttached: BalanceTransferYesNo,
    finalCustomer: BalanceTransferYesNo,       
    billingAnalyst: ContentHubUser | null,
    _attachments: ContentHubAttachments | null,
    _workflowStep: string
}

export const BalanceTransferSchema: ObjectSchema<BalanceTransfer> = object({
    requestor: ContentHubUserSchema,
    counterpartyFrom: string().defined().required("Select Counterparty")
        .transform((value) => counterpartyToSingleValue(value, "name")),
    accountNumberFrom: string().defined().required("Provide account number"),
    transactionAmountFrom: number().required("Provide transaction amount").default(0),
    counterpartyTo: string().defined().required("Select Counterparty")
        .transform((value) => counterpartyToSingleValue(value, "name")),
    accountNumberTo: string().defined().required("Provide account number"),
    transactionAmountTo: number().required("Provide transaction amount").default(0),
    requestorComments: string().defined().required("Add Comments"),    
    status: string<BalanceTransferStatusOptions>().required().default('Ready for Credit Approval'),
    clientApprovalAttached: string<BalanceTransferYesNo>().required("Select an option")
        .transform((value) => dropDownToString(value)),
    creditDocumentationAttached: string<BalanceTransferYesNo>().required("Select an option")
        .transform((value) => dropDownToString(value)),
    balanceTransferTemplateAttached: string<BalanceTransferYesNo>().required("Select an option")
        .transform((value) => dropDownToString(value)),
    finalCustomer: string<BalanceTransferYesNo>().required("Select an option")
        .transform((value) => dropDownToString(value)),
    billingAnalyst: ContentHubUserSchema.nullable(),
    _attachments: ContentHubAttachmentsSchema.nullable(),
     _workflowStep: string().defined().default("NewRequest"),
})

