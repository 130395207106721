import { ContentHubItemElement } from "../../../../Services/types/ContentHub/ContentHubItemElement";
import { ContentHubListsElement } from "../../../../Services/types/ContentHub/ContentHubListsElement";
import { ContentHubSiteElement } from "../../../../Services/types/ContentHub/ContentHubSiteElement";
import { BadDebt } from "../../../../Services/types/ContentHub/Credlt/BadDebt";
import { ContentHubUser } from "../../../../Services/types/General/user";
import { formatDate } from "../../../../utils/utils";
import { ContentHubListRegistry } from "../../SitesRegistry";
import { FormBadDebt } from "./BadDebtForm";

export const BadDebtListRegistry: ContentHubListRegistry = {
  name: "BadDebt",
  header: "Bad Debt",
  columns: [
    { key: "id", displayName: "ID", visible: false },
    { key: "shortId", displayName: "ID", style: { width: "10ch" } },
    { key: "counterparty", displayName: "Counterparty" },
    {
      key: "accountNumber",
      displayName: "Account Number",
      style: { width: "10ch" },
    },
    {
      key: "writeOffTotal",
      displayName: "Write-Off Total",
      style: { width: "16ch" },
    },
    { key: "requestor", displayName: "Requestor", style: { width: "25ch" } },
    {
      key: "requestStatus",
      displayName: "Request Status",
      style: { width: "35ch" },
    },
    {
      key: "createdDate",
      displayName: "Created Date",
      style: { width: "24ch" },
    },
  ],
  mapper: function (item: ContentHubItemElement<BadDebt>) {
    const requestor = item.fields.requestor.value as ContentHubUser;
    return {
      id: item.id,
      shortId: item.id.substring(28),
      counterparty: item.fields.counterparty?.value,
      accountNumber: item.fields.accountNumber?.value,
      writeOffTotal: Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(item.fields.writeOffTotal?.value ?? 0),
      requestor: requestor.name,
      requestStatus: item.fields.requestStatus.value,
      createdDate: formatDate(new Date(item.createdDate)),
    };
  },
  renderForm: function (
    siteDefinition: ContentHubSiteElement,
    listDefinition: ContentHubListsElement,
    selectedItem: ContentHubItemElement<BadDebt>,
    drawerOpen: boolean,
    onDrawerClosed: (result: BadDebt | null, changesApplied: boolean) => void
  ): JSX.Element {
    return (
      <FormBadDebt
        site={siteDefinition}
        list={listDefinition}
        itemDefinition={selectedItem}
        drawerOpen={drawerOpen!}
        onDrawerClosed={onDrawerClosed}
      />
    );
  },
};
