import {
  Field,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  SearchBox,
} from "@fluentui/react-components";
import { KeyboardEvent, useContext, useEffect, useState } from "react";
import * as ApprovalService  from "../../../Services/approvals/ApprovalService";
import {
  ApprovalRequest,
  ApprovalStatus,
} from "../../../Services/types/approvalRequest";
import { SearchRequest } from "../../../Services/types/searchRequest";
import { TeamsFxContext } from "../../Context";
import { ApprovalDrawerComponent } from "../ApprovalDrawer/ApprovalDrawer";
import { ApprovalStatusDropDownComponent } from "../ApprovalStatusDropDown/ApprovalStatusDropDown";
import { ApprovalListComponent } from "../ApprovalsList/ApprovalList";
import "./Approvals.css";

export const ApprovalsComponent = ({
  email,
  listType,
  selectedId = "",
  onDrawerClosed,
}: {
  email: string;
  listType: "Approvers" | "Requestor";
  selectedId: string;
  onDrawerClosed: () => void;
}) => {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const [selectedStatus, setSelectedStatus] = useState<ApprovalStatus>(
    listType === "Approvers" ? ApprovalStatus.Pending : ApprovalStatus.Approved
  );
  const [selectedItem, setSelectedItem] = useState<ApprovalRequest>();
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [approvals, setApprovals] = useState<ApprovalRequest[]>([]);
  const [continuationToken, setContinuationToken] = useState<
    string | undefined
  >();
  const [isRepopulating, setIsRepopulating] = useState<boolean>(false);
  const [textFilter, setTextFilter] = useState<string>("");
  const [lastTextFilterUpdate, setLastTextFilterUpdate] = useState<Date>(
    new Date()
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [searchErrorFlag, setSearchErrorFlag] = useState<boolean>(false);

  const hasMoreItems = continuationToken !== undefined;

  useEffect(() => {
    if (selectedId !== "") {
      setSelectedItemId(selectedId);
    } else {
      setSelectedItemId(null);
    }
  }, [selectedId]);

  useEffect(() => {
    if (selectedItemId != null) {
      setDrawerOpen(true);
    }
  }, [selectedItemId]);

  const loadApprovals = async () => {
    setIsLoading(true);
    const searchRequest: SearchRequest = {
      status: selectedStatus,
      listType: listType,
      continuationToken: continuationToken,
      maxItems: 25,
    };

    if (textFilter !== "") {
      searchRequest.filter = textFilter;
    }

    try {
      if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
      }
      const response = await ApprovalService.getApprovals(
        searchRequest,
        teamsUserCredential
      );
      if (continuationToken === undefined) {
        setApprovals(response.items);
      } else {
        setApprovals([...approvals, ...response.items]);
      }

      const token = response.continuationToken ?? undefined;

      setCurrentPage(currentPage + 1);
      setContinuationToken(token);
      setSearchErrorFlag(false);
    } catch {
      setSearchErrorFlag(true);
    }
    setIsLoading(false);
  };

  const openDrawer = (item: ApprovalRequest) => {
    setSelectedItem(item);
    setDrawerOpen(true);
  };

  const closeDrawer = (result: ApprovalRequest | null) => {
    if (selectedItemId != null) {
      setSelectedItemId(null);
    }
    if (result != null) {
      const newApprovals = [...approvals];
      const index = newApprovals.findIndex((a) => a.id === result.id);
      if (index >= 0) {
        newApprovals[index] = result;
        setApprovals(newApprovals);
        setSelectedItem(result);
      }
    }

    setDrawerOpen(false);
    onDrawerClosed();
  };

  useEffect(() => {
    setIsRepopulating(true);
  }, []);

  useEffect(() => {
    if (isRepopulating) {
      loadApprovals();
      setIsRepopulating(false);
    }
  }, [isRepopulating]);

  useEffect(() => {
    setContinuationToken(undefined);
    setApprovals([]);
    setIsRepopulating(true);
    setCurrentPage(0);
  }, [email, listType, selectedStatus, lastTextFilterUpdate]);

  const onSearchKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const target = event.target! as HTMLInputElement;
    const val = (target.value as string).trim();

    if (event.keyCode === 13) {
      const currentDate = new Date();
      const diff = currentDate.getTime() - lastTextFilterUpdate.getTime();

      if (val !== textFilter || diff > 1500) {
        setApprovals([]);
        setTextFilter(val);
        setLastTextFilterUpdate(currentDate);
      }
    }
  };

  return (
    <div className="flexfill nooverflow">
      {searchErrorFlag && (
        <MessageBar intent="error" style={{ marginBottom: "5px" }}>
          <MessageBarBody>
            <MessageBarTitle>Error</MessageBarTitle>
            Could not retrieve approvals from the server.
          </MessageBarBody>
        </MessageBar>
      )}
      <div className="approvalControls">
        <div className="approvalDropdown">
          <ApprovalStatusDropDownComponent
            onSelectionChange={(s) =>
              setSelectedStatus(s ?? ApprovalStatus.Pending)
            }
            defaultStatus={selectedStatus}
          />
        </div>
        <div className="approvalSearch">
          <Field label={"Search"}>
            <SearchBox
              appearance="underline"
              onKeyDown={onSearchKeyDown}
            ></SearchBox>
          </Field>
        </div>
      </div>
      <div className="flexfill nooverflow">
        <ApprovalListComponent
          listType={listType}
          onItemClicked={openDrawer}
          onNext={loadApprovals}
          approvals={approvals}
          hasMoreItems={hasMoreItems}
          isLoading={isLoading}
          showEndMessage={currentPage > 0 && !hasMoreItems}
        />
      </div>
      <ApprovalDrawerComponent
        drawerOpen={drawerOpen}
        onDrawerClosed={closeDrawer}
        approvalRequest={selectedItemId ?? selectedItem ?? null}
        userEmail={email}
        closeOnUpdate={selectedItemId == null}
      />
    </div>
  );
};
