import { useContext, useEffect, useState } from "react";
import { TeamsFxContext } from "../../src/components/Context";
import { User } from "../Services/types/General/user";
import CallUserInfoFunction from "../Services/userInfo";

const useUser = () => {
  const [needConsent, setNeedConsent] = useState(false);
  const [userInfo, setUserInfo] = useState<User>();
  const [errorResponse, setErrorResponse] = useState<Error>();
  const { teamsUserCredential } = useContext(TeamsFxContext);

  const fetchUserInfo = async () => {
    try {
      if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
      }
      if (needConsent) {
        await teamsUserCredential!.login(["User.Read"]);
        setNeedConsent(false);
      }
      try {
        const functionRes = await CallUserInfoFunction(teamsUserCredential);

        setUserInfo(functionRes);
        return functionRes;
      } catch (error) {
        if (
          (error as Error).message.includes(
            "The application may not be authorized."
          )
        ) {
          setNeedConsent(true);
        } else {
          throw error;
        }
      }
    } catch (error) {
      setErrorResponse(error as Error);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return { userInfo: userInfo, error: errorResponse };
};

export default useUser;
