import { object, string, ObjectSchema } from 'yup';

export type User = {
    id: string;
    displayName: string;
    jobTitle: string;
    mail: string;
    userPrincipalName: string
    department: string
}

export type ContentHubUser = {
    id: string,
    name: string,
    jobTitle: string,
    email: string,
    userPrincipalName: string,
    department?: string | null
}

export const ContentHubUserSchema: ObjectSchema<ContentHubUser> = object({
    id: string().defined().required("Provide Azure ID"),
    name:  string().defined().required("Provide Name"),
    jobTitle: string().defined().required("Provide Job Title"),
    email: string().defined().required("Provide Email"),
    userPrincipalName: string().defined().required("Provide User Principal Name"),
    department: string().nullable()
})