import { useContext } from "react";
import "./ContentHubSite.css";
import useContentHubSite from "../../../Hooks/useContentHubSite";
import { SelectedContentHubListContext } from "../../../Context/SelectedContentHubListContext";
import { ContentHubSiteRegistry } from "../../Sites/SitesRegistry";
import ContentHubList from "../ContentHubList/ContentHubList";

export function ContentHubSite(props: {
  siteRegistry: ContentHubSiteRegistry;
  openForm?: boolean;
  onFormStateChanged?: (state: boolean) => void;
}) {
  const [selectedContentHub] = useContext(
    SelectedContentHubListContext
  );
  const contentHubSite = useContentHubSite(props.siteRegistry.name);

  const selectedList = props.siteRegistry.lists.find(
    (l) => l.name === selectedContentHub.selectedList
  );

  return (
    <div className="welcome page flexfill nooverflow">
      <div
        style={{ paddingTop: 0 }}
        className="narrow page-padding flexfill nooverflow"
      >
        {contentHubSite.siteInfo !== undefined && (
          <div className="flexfill nooverflow">
            <div className="flexfill nooverflow">
              <div className="flexfill nooverflow">
                {selectedList && (
                  <ContentHubList
                    listRegistry={selectedList}
                    siteRegistry={props.siteRegistry}
                    openForm={props.openForm}
                    onFormStateChanged={props.onFormStateChanged}
                  ></ContentHubList>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
