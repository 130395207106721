export type ApprovalRequestResponse = {
  items: ApprovalRequest[];
  continuationToken: string;
};
export type ApprovalRequest = {
  id: string;
  createdDate: Date;
  modifiedDate?: Date;
  status: ApprovalStatus;
  comment?: string;
  title: string;
  requestor: UserInformation;
  approvers: UserInformation[];
  updates?: ApprovalAction[];
  links?: ApprovalLink[];
  parameters: object;
  reminder?: ApprovalReminder;
  messageTemplate?: string;
  metadata: object;
  correlationKey?: string;
  sourceWorkflow: string;
  adapter: AdapterInfo;
  schemaVersion: string;
};

export interface ApprovalAction {
  status: ApprovalStatus;
  update: Record<string, unknown>;
}

export interface ApprovalLink {
  text: string;
  url: string;
}

export interface ApprovalReminder {
  hours: number;
  sent: boolean;
  notificationSendDate: Date;
}

export type UserInformation = {
  id: string;
  name: string;
  email: string;
  department: string;
  jobTitle: string;
};

export enum ApprovalStatus {
  Denied = 0,
  Approved = 1,
  Pending = 2,
  Cancelled = 3,
  New = 4,
  All = 5,
  RevisionRequired = 6,
}

export function ApprovalStatusToString(status: ApprovalStatus): string {
  if (typeof status === "string") {
    if (status == "RevisionRequired") return "Revision Required";
    return status;
  }

  if (status == ApprovalStatus.RevisionRequired) return "Revision Required";
  return ApprovalStatus[status];
}
export function ApprovalStatusToInt(status: ApprovalStatus): number {
  return status;
}

export type AdapterInfo = {
  name: string;
  schemaVersion: string;
};
