import config from "../../components/sample/lib/config";
import {
  BearerTokenAuthProvider,
  createApiClient,
  TeamsUserCredential,
} from "@microsoft/teamsfx";
import { ContentHubUser, User } from "../types/General/user";

const apiBaseUrl = config.apiEndpoint + "/api/";

async function getToken(credential: TeamsUserCredential) {
  return (await credential.getToken(""))!.token;
}

function getApiClient(credential: TeamsUserCredential) {
  const apiClient = createApiClient(
    apiBaseUrl,
    new BearerTokenAuthProvider(() => getToken(credential))
  );
  return apiClient;
}

export async function getUserById(
  userId: string,
  teamsUserCredential: TeamsUserCredential
) {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const response = await apiClient.get(`user/id/${userId}`);
    return response.data as ContentHubUser;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getUser(
  userSearch: string,
  teamsUserCredential: TeamsUserCredential
) {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const response = await apiClient.get(`user/${encodeURIComponent(userSearch)}`);
    return response.data as ContentHubUser[];
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getUserInGroup(
  userSearch: string,
  groupName: string,
  teamsUserCredential: TeamsUserCredential
) {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const response = await apiClient.get(
      `group/${groupName}/member/${userSearch}`
    );
    return response.data as ContentHubUser[];
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const userProfileToContentHubUser = (user: User) => {
  try {
    if (user !== undefined) {
      const contentHubUser: ContentHubUser = {
        id: user.id,
        name: user.displayName,
        jobTitle: user.jobTitle,
        email: user.mail,
        userPrincipalName: user.userPrincipalName,
        department: user.department,
      };
      return contentHubUser;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};
