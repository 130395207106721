import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Caption1,
  Tooltip,
  Card,
  CardHeader,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  Skeleton,
  SkeletonItem,
  Label,
  Button,
} from "@fluentui/react-components";
import { FileUploader } from "react-drag-drop-files";
import { Dismiss12Filled, FluentIconsProps } from "@fluentui/react-icons";

import * as SharePointDriveService from "../../../Services/sharepoint/SharePointDriveService";
import { TeamsFxContext } from "../../Context";
import { AttachmentFile } from "../../../Services/types/SharePoint/SharePointDriveItems";
import "../FileUploader/FileUploaderCommon.css";
import { ContentHubAttachments } from "../../../Services/types/ContentHub/ContentHubAttachments";
import { ContentHubListsElement } from "../../../Services/types/ContentHub/ContentHubListsElement";

export const FileUploaderCommon = ({
  reload,
  attachment,
  filesOnForm,
  fileTypes,
  listDefinition,
  disabled,
}: {
  reload: boolean;
  attachment: ContentHubAttachments | null | undefined;
  filesOnForm?: (value: File[]) => void;
  fileTypes?: string[] | null | undefined;
  listDefinition: ContentHubListsElement;
  disabled?: boolean;
}) => {
  const { teamsUserCredential } = useContext(TeamsFxContext);

  const [driveId, setDriveId] = useState<string | undefined>(undefined);

  useEffect(() => {
    setDriveId(
      listDefinition.fields
        .find((f) => f.name === "_attachments")
        ?.folderId?.split("/")[0]
    );
  }, [listDefinition]);
  //Files
  const [attachmentNames, setAttachmentsName] = useState<File[]>([]); //Files to be uploaded
  const [attachedFiles, setAttachedFiles] = useState<
    AttachmentFile[] | undefined
  >(undefined); //Files already in Drive

  //On Load
  useEffect(() => {
    loadAttachments();
  }, [driveId]);

  //Reload Files
  useEffect(() => {
    if (reload) {
      loadAttachments();
    }
  }, [attachment]);

  //File Uploader Methods
  const filesAttached = useCallback(
    (filesData: FileList) => {
      //Attach Names to Display
      const arrayName: File[] = [];
      for (let i = 0; i < filesData.length; i++) {
        arrayName.push(filesData[i]);
      }
      setAttachmentsName(arrayName);

      //Set Files Objects on Form
      filesOnForm?.(arrayName);
    },
    [filesOnForm]
  );

  //Load Files
  const loadAttachments = useCallback(async () => {
    if (!driveId) return;
    setAttachedFiles(undefined);
    setAttachmentsName([]);

    if (attachment) {
      const itemFolderId = attachment.itemFolderId;

      if (
        itemFolderId !== null &&
        itemFolderId !== undefined &&
        itemFolderId !== ""
      ) {
        const filesInDrive = await SharePointDriveService.getAttachments(
          driveId!,
          itemFolderId,
          teamsUserCredential!
        );
        setAttachedFiles(filesInDrive);
      } else {
        const empty: AttachmentFile[] = [];
        setAttachedFiles(empty);
      }
    } else {
      const empty: AttachmentFile[] = [];
      setAttachedFiles(empty);
    }
  }, [attachment, driveId, teamsUserCredential]);

  //Delete Files from Drive
  const deleteAttachedFile = useCallback(
    async (event: React.MouseEvent, fileId: string) => {
      event.stopPropagation();

      await SharePointDriveService.deleteAttachment(
        driveId!,
        fileId,
        teamsUserCredential!
      );
      loadAttachments();
    },
    [driveId, loadAttachments, teamsUserCredential]
  );

  //Remove File From Array
  const removeFile = useCallback(
    (fileName: string) => {
      const currentFiles = attachmentNames;

      const newFiles = currentFiles?.filter((file) => {
        return file.name !== fileName;
      });
      setAttachmentsName(newFiles);
      filesOnForm?.(newFiles);
    },
    [attachmentNames, filesOnForm]
  );

  const iconStyleProps: FluentIconsProps = {
    primaryFill: "red",
    className: "iconClass",
  };

  return (
    <div className="attachmentsArea">
      <div id="filesDiv">
        <h3>Files Attached:</h3>
        {attachedFiles ? (
          attachedFiles.length > 0 ? (
            attachedFiles.map((file) => {
              return (
                <div id={file.id} key={file.id}>
                  <Card
                    className="fileCard"
                    onClick={() => window.open(file.webUrl, "_blank")}
                  >
                    <CardHeader
                      header={file.name}
                      description={<Caption1>Download</Caption1>}
                      action={
                        !disabled ? (
                          <Tooltip content="Delete File" relationship="label">
                            <Button
                              icon={
                                <Dismiss12Filled
                                  {...iconStyleProps}
                                ></Dismiss12Filled>
                              }
                              onClick={(event) =>
                                deleteAttachedFile(event, file.id)
                              }
                            ></Button>
                          </Tooltip>
                        ) : undefined
                      }
                    ></CardHeader>
                  </Card>
                </div>
              );
            })
          ) : (
            <MessageBar key="info-attachments" intent="info">
              <MessageBarBody>
                <MessageBarTitle>Attachments Files: </MessageBarTitle>
                No attachments have been added to this ticket.
              </MessageBarBody>
            </MessageBar>
          )
        ) : (
          <Skeleton>
            {Array(3)
              .fill(null)
              .map((_, i) => (
                <div
                  key={i}
                  style={{
                    marginBottom: "4px",
                    marginTop: "4px",
                    display: "grid",
                    gridTemplateColumns: "20ch 20ch min-content",
                    justifyContent: "space-between",
                  }}
                >
                  <SkeletonItem shape="rectangle" />
                  <SkeletonItem shape="rectangle" />
                </div>
              ))}
          </Skeleton>
        )}
      </div>

      <div className="fileUploaderDiv">
        <Label htmlFor="attachments">Add Attachments:</Label>
        <FileUploader
          disabled={disabled}
          fileOrFiles={null}
          multiple={true}
          handleChange={filesAttached}
          Name="attachments"
          types={fileTypes}
          dropMessageStyle={{ backgroundColor: "#464EB8" }}
        />
      </div>

      {!disabled && attachmentNames.length > 0 && (
        <div id="newfiles">
          <div>
            <h3>Files To be Uploaded:</h3>
          </div>
          <div>
            {attachmentNames.map((file, index) => {
              return (
                <Card className="newFile" key={index}>
                  <CardHeader
                    header={file.name}
                    description={file.size}
                    action={
                      <Tooltip content="Remove attachment" relationship="label">
                        <Button
                          icon={<Dismiss12Filled {...iconStyleProps} />}
                          appearance="transparent"
                          aria-label="More options"
                          shape="circular"
                          onClick={() => removeFile(file.name)}
                        ></Button>
                      </Tooltip>
                    }
                  ></CardHeader>
                </Card>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
