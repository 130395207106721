import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbDivider,
  BreadcrumbItem,
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuProps,
  MenuTrigger,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  Spinner,
} from "@fluentui/react-components";
import { AddFilled } from "@fluentui/react-icons";
import { useContext, useEffect, useState } from "react";
import { matchPath } from "react-router-dom";
import { DeepLinkContext } from "../Context/DeepLinkContext";
import { SelectedContentHubListContext } from "../Context/SelectedContentHubListContext";
import useSelectedContentHub from "../Hooks/useSelectedContentHub";
import { ContentHubSite } from "./ContentHubComponents/ContentHubSite/ContentHubSite";
import { TeamsFxContext } from "./Context";
import {
  ContentHubSiteRegistry,
  ContentHubSitesRegistry,
} from "./Sites/SitesRegistry";
import "./Welcome.css";
import { ContentHubContext } from "../Context/ContentHubContext";
import { app } from "@microsoft/teams-js";

//Get Info From MS Graph Function App
export default function Workflows() {
  const { themeString } = useContext(TeamsFxContext);
  const [deepLink, setDeepLink] = useContext(DeepLinkContext);
  const contentHubContext = useContext(ContentHubContext);

  const [
    selectedContentHub,
    setSelectedContentHubSite,
    setSelectedContentHubList,
    setSelectedContentHubItem,
  ] = useSelectedContentHub();

  const [registry, setRegistry] = useState<ContentHubSiteRegistry[]>([]);

  useEffect(() => {
    if (contentHubContext.isLoading === false) {
      const registeredSites = ContentHubSitesRegistry.map((sr) => {
        const site = contentHubContext.sites.find(
          (s) =>
            s.name.localeCompare(sr.name, undefined, {
              sensitivity: "base",
            }) === 0
        );
        if (site === undefined) return null;
        return {
          id: site.id,
          name: sr.name,
          text: sr.text,
          lists: sr.lists
            .map((lr) => {
              const list = contentHubContext.lists[site!.id].find(
                (l) =>
                  l.name.localeCompare(lr.name, undefined, {
                    sensitivity: "base",
                  }) === 0
              );
              if (list === undefined) return null;
              return { ...lr, id: list.id };
            })
            .filter((lr) => lr !== null)
            .map((lr) => lr!),
        } as ContentHubSiteRegistry;
      })
        .filter((sr) => sr !== null)
        .map((sr) => sr!);

      setRegistry(registeredSites);
    }
    app.getContext().then((value) => {
      const testContext = value;
      console.log(testContext);
    });
  }, [
    contentHubContext.isLoading,
    contentHubContext.lists,
    contentHubContext.sites,
  ]);

  useEffect(() => {
    if (deepLink == null) return;
    const locationMatch = matchPath(
      "/workflows/:siteName?/:listName?/:itemId?",
      deepLink
    );
    const siteName = locationMatch?.params["siteName"];
    const listName = locationMatch?.params["listName"];
    const itemId = locationMatch?.params["itemId"];
    setSelectedContentHubSite(siteName ?? null);
    setSelectedContentHubList(listName ?? null);
    setSelectedContentHubItem(itemId ?? null);
    setDeepLink(null);
  }, [deepLink]);

  useEffect(() => {
    if (selectedContentHub.selectedSite == null) {
      setOpenSiteSelect(true);
    } else {
      setOpenSiteSelect(false);
    }
    if (selectedContentHub.selectedList == null) {
      setOpenListSelect(true);
    } else {
      setOpenListSelect(false);
    }
  }, [selectedContentHub]);

  const selectSite = (siteName: string) => {
    setSelectedContentHubSite(siteName);
    setSelectedContentHubList(null);
    setSelectedContentHubItem(null);
    setOpenSiteSelect(false);
  };

  const selectList = (listName: string) => {
    setSelectedContentHubList(listName);
    setSelectedContentHubItem(null);
    setOpenListSelect(false);
  };

  const [openListSelect, setOpenListSelect] = useState<boolean>(false);
  const onListSelectOpenChange: MenuProps["onOpenChange"] = (_e, data) => {
    if (selectedContentHub.selectedList == null) {
      setOpenListSelect(true);
    } else {
      setOpenListSelect(data.open);
    }
  };

  const [openSiteSelect, setOpenSiteSelect] = useState<boolean>(false);
  const onSiteSelectOpenChange: MenuProps["onOpenChange"] = (_e, data) => {
    if (selectedContentHub.selectedSite == null) {
      setOpenSiteSelect(true);
    } else {
      setOpenSiteSelect(data.open);
    }
  };

  const [openForm, setOpenForm] = useState<boolean>(false);

  return (
    <div
      className={
        themeString === "default"
          ? "light"
          : themeString === "dark"
          ? "dark"
          : "contrast"
      }
    >
      {contentHubContext.isLoading && <Spinner style={{ margin: 100 }} />}
      {!contentHubContext.isLoading &&
        contentHubContext.error !== undefined && (
          <div style={{ margin: "auto" }}>
            <MessageBar intent="error">
              <MessageBarBody>
                <MessageBarTitle>Error</MessageBarTitle>
                {(contentHubContext.error as Error).message}
              </MessageBarBody>
            </MessageBar>
          </div>
        )}
      {!contentHubContext.isLoading &&
        contentHubContext.error === undefined && (
          <SelectedContentHubListContext.Provider
            value={[
              selectedContentHub,
              setSelectedContentHubSite,
              setSelectedContentHubList,
              setSelectedContentHubItem,
            ]}
          >
            <div className="flexfill nooverflow">
              <div className="narrow page-padding breadcrumb-container">
                <Breadcrumb size="large">
                  <BreadcrumbItem>
                    <Menu
                      open={openSiteSelect}
                      onOpenChange={onSiteSelectOpenChange}
                    >
                      <MenuTrigger>
                        {selectedContentHub.selectedSite ? (
                          <BreadcrumbButton>
                            {
                              registry.find(
                                (s) =>
                                  selectedContentHub.selectedSite?.localeCompare(
                                    s.name,
                                    undefined,
                                    {
                                      sensitivity: "base",
                                    }
                                  ) === 0
                              )?.text
                            }
                          </BreadcrumbButton>
                        ) : (
                          <BreadcrumbButton>
                            <b>Select a site</b>
                          </BreadcrumbButton>
                        )}
                      </MenuTrigger>
                      <MenuPopover>
                        <MenuList>
                          {registry.map((s) => (
                            <MenuItem
                              key={s.name}
                              onClick={() => selectSite(s.name)}
                            >
                              {s.text}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  </BreadcrumbItem>
                  {selectedContentHub.selectedSite && (
                    <>
                      <BreadcrumbDivider />
                      <BreadcrumbItem>
                        <Menu
                          open={openListSelect && !openSiteSelect}
                          onOpenChange={onListSelectOpenChange}
                        >
                          <MenuTrigger>
                            {selectedContentHub.selectedList ? (
                              <BreadcrumbButton>
                                {
                                  registry
                                    .find(
                                      (s) =>
                                        selectedContentHub.selectedSite?.localeCompare(
                                          s.name,
                                          undefined,
                                          { sensitivity: "base" }
                                        ) === 0
                                    )
                                    ?.lists.find(
                                      (l) =>
                                        selectedContentHub.selectedList?.localeCompare(
                                          l.name,
                                          undefined,
                                          { sensitivity: "base" }
                                        ) === 0
                                    )?.header
                                }
                              </BreadcrumbButton>
                            ) : (
                              <BreadcrumbButton>
                                <b>Select a list</b>
                              </BreadcrumbButton>
                            )}
                          </MenuTrigger>
                          <MenuPopover>
                            <MenuList>
                              {registry
                                .find(
                                  (s) =>
                                    selectedContentHub.selectedSite?.localeCompare(
                                      s.name,
                                      undefined,
                                      { sensitivity: "base" }
                                    ) === 0
                                )
                                ?.lists.map((s) => (
                                  <MenuItem
                                    key={s.name}
                                    onClick={() => selectList(s.name)}
                                  >
                                    {s.header}
                                  </MenuItem>
                                ))}
                            </MenuList>
                          </MenuPopover>
                        </Menu>
                      </BreadcrumbItem>
                    </>
                  )}
                </Breadcrumb>
                {selectedContentHub?.selectedList && (
                  <Button
                    size="large"
                    appearance="primary"
                    onClick={() => {
                      setOpenForm(true);
                    }}
                    disabled={openForm}
                  >
                    <AddFilled />
                    &nbsp;New Item
                  </Button>
                )}
              </div>
              <div className="flexfill nooverflow">
                {registry
                  .filter(
                    (s) =>
                      selectedContentHub.selectedSite?.localeCompare(
                        s.name,
                        undefined,
                        { sensitivity: "base" }
                      ) === 0
                  )
                  .map((s) => (
                    <ContentHubSite
                      siteRegistry={s}
                      key={s.name}
                      openForm={openForm}
                      onFormStateChanged={(state: boolean) => {
                        setOpenForm(state);
                      }}
                    ></ContentHubSite>
                  ))}
              </div>
            </div>
          </SelectedContentHubListContext.Provider>
        )}
    </div>
  );
}
