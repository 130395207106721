import { useState, useEffect } from "react";
type SelectedContentHubType = {
  selectedSite: string | null;
  selectedList: string | null;
  selectedItem: string | null;
};
export default function useSelectedContentHub(): [
  SelectedContentHubType,
  (value: string | null) => void,
  (value: string | null) => void,
  (value: string | null) => void
] {
  const [site, setSite] = useState<string | null>(null);
  const [list, setList] = useState<string | null>(null);
  const [item, setItem] = useState<string | null>(null);
  const [selectedContentHub, setSelectedContentHub] =
    useState<SelectedContentHubType>({
      selectedSite: site,
      selectedList: list,
      selectedItem: item,
    });

  useEffect(() => {
    setSelectedContentHub({
      selectedSite: site,
      selectedList: list,
      selectedItem: item,
    });
  }, [site, list, item]);

  return [selectedContentHub, setSite, setList, setItem];
}
