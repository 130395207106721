import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  DialogOpenChangeData,
  DialogOpenChangeEvent,
} from "@fluentui/react-components";
import { useState, useEffect } from "react";

export const ConfirmationDialog = ({
  title,
  message,
  actions,
  openDialog,
  choiceAction,
}: {
  title: string;
  message: string;
  actions: string[];
  openDialog: boolean;
  choiceAction?: (action: string) => void;
}) => {
  const [open, setOpen] = useState<boolean>(openDialog);
  const dialogStateChange = (_event: DialogOpenChangeEvent, data: DialogOpenChangeData) => {
    setOpen(data.open);
    choiceAction?.("Cancel")
  };

  const optionSelected = (option: string) => {
    choiceAction?.(option);
  };

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  return (
    <Dialog
      open={open}
      onOpenChange={dialogStateChange}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{message}</DialogContent>
          <DialogActions>
            {actions.map((action, index) => {
              return (
                <Button onClick={() => optionSelected(action)} key={index}>
                  {action}
                </Button>
              );
            })}
            <Button
              appearance="secondary"
              onClick={() => optionSelected("Cancel")}
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
