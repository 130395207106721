import { useContext, useState, useEffect } from "react";
import { ContentHubSiteElement } from "../Services/types/ContentHub/ContentHubSiteElement";
import { ContentHubContext } from "../Context/ContentHubContext";

export default function useContentHubSite(siteName: string) {
  const contentHubContext = useContext(ContentHubContext);

  //Load Site Info
  const [siteInfo, setSiteInfo] = useState<ContentHubSiteElement | undefined>(
    undefined
  );
  useEffect(() => {
    if (contentHubContext.isLoading === true) return;
    const info = contentHubContext.sites.find(
      (s) =>
        s.name.localeCompare(siteName, undefined, { sensitivity: "base" }) === 0
    );
    setSiteInfo(info);
  }, [siteName, contentHubContext]);

  return { siteInfo: siteInfo };
}
