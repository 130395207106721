import { useState, useContext, useEffect } from "react";
import * as ContentHubService from "../Services/contentHub/ContentHubService";
import { TeamsFxContext } from "../components/Context";
import { ContentHubSiteElement } from "../Services/types/ContentHub/ContentHubSiteElement";
import { ContentHubListsElement } from "../Services/types/ContentHub/ContentHubListsElement";
import { DropDownData } from "../Services/types/General/DropDownData";
import { ContentHubItemElement } from "../Services/types/ContentHub/ContentHubItemElement";
import { ContentHubInsertItemRequest } from "../Services/types/ContentHub/ContentHubInsertItemRequest";
import { ContentHubUpdateItemRequest } from "../Services/types/ContentHub/ContentHubUpdateItemRequest";

const useContentHubForm = <T>({
  siteDefinition,
  listDefinition,
}: {
  siteDefinition: ContentHubSiteElement | undefined;
  listDefinition: ContentHubListsElement | undefined;
  itemDefinition?: ContentHubItemElement<T> | null | undefined;
}) => {
  const { teamsUserCredential } = useContext(TeamsFxContext);

  //Item
  const [item, setItemForm] = useState<
    ContentHubItemElement<T> | null | undefined
  >();
  const [correlationKey, setCorrelationKey] = useState<string>("");

  useEffect(() => {
    setCorrelationKey(siteDefinition?.id + "/" + listDefinition?.id + "/" + item?.id)
  }, [item])

  //Files
  const [reloadFiles, setReloadFiles] = useState<boolean>(false);

  const [submitStatus, setSubmitStatus] = useState<boolean | null>(null);
  const [beingSubmitted, setBeingSubmmitted] = useState<boolean>(false);
  const submitHandler = async (
    data: { [K in keyof T]: T[K] },
    id: string | null | undefined,
    edit: boolean,
    files?: File[]
  ) => {
    if (!edit) {
      await createItem(data, files);
    } else {
      if (id !== null && id !== undefined) {
        await updateItem(data, id, files);
      }
    }
  };

  //Create Item
  const createItem = async (
    fields: { [K in keyof T]: T[K] },
    files?: File[] | null | undefined
  ) => {
    try {
      setBeingSubmmitted(true);
      if (siteDefinition && listDefinition) {
        const request: ContentHubInsertItemRequest = {
          fields: fields,
        };

        const attachmentsField = listDefinition.fields.find(
          (f) => f.name === "_attachments"
        );
        let driveId: string | undefined = undefined;
        let folderId: string | undefined = undefined;
        if (attachmentsField) {
          const fId = attachmentsField.folderId!;
          [driveId, folderId] = fId.split("/");
        }

        const createResponse = (await ContentHubService.createItem(
          siteDefinition.id,
          listDefinition.id,
          request,
          teamsUserCredential!,
          driveId,
          folderId,
          files ?? undefined
        )) as ContentHubItemElement<T>;
        setSubmitStatus(true);
        setBeingSubmmitted(false);
        //setReloadFiles(true);
        setItemForm(createResponse);
      }
    } catch {
      setSubmitStatus(false);
      setBeingSubmmitted(false);
    }
  };

  //Update Item
  const updateItem = async (
    fields: { [K in keyof T]: T[K] },
    itemId: string,
    files?: File[]
  ) => {
    try {
      setBeingSubmmitted(true);
      if (siteDefinition && listDefinition) {
        const request: ContentHubUpdateItemRequest = {
          fields: fields,
          ignoreLock: false,
        };
        const attachmentsField = listDefinition.fields.find(
          (f) => f.name === "_attachments"
        );
        let driveId: string | undefined = undefined;
        let folderId: string | undefined = undefined;
        if (attachmentsField) {
          const fId = attachmentsField.folderId!;
          [driveId, folderId] = fId.split("/");
        }

        const updateResponse = (await ContentHubService.updateItems(
          siteDefinition.id,
          listDefinition.id,
          itemId,
          request,
          teamsUserCredential!,
          driveId,
          folderId,
          files
        )) as ContentHubItemElement<T>;
        setSubmitStatus(true);
        setItemForm(updateResponse);
      }
    } catch {
      setSubmitStatus(false);
      setBeingSubmmitted(false);
    } finally {
      setBeingSubmmitted(false);
    }
  };

  //Set Option To DropDown
  const getOptions = (columnName: string) => {
    if (columnName !== "") {
      const columnInList = listDefinition?.fields.find((field) => {
        return field.name === columnName;
      });

      if (columnInList?.type === "choice") {
        const newDropDown: DropDownData[] = [];

        columnInList.options?.forEach((value) => {
          const info: DropDownData = {
            label: value,
            value: value,
          };
          newDropDown.push(info);
        });

        return newDropDown;
      }
    }

    const emtpy: DropDownData[] = [];
    return emtpy;
  };

  return {
    correlationKey,
    item,
    submitStatus,
    reloadFiles,
    setReloadFiles,
    beingSubmitted,
    setBeingSubmmitted,
    setSubmitStatus,
    setItemForm,
    submitHandler,
    getOptions,
  };
};

export default useContentHubForm;
