import { StylesConfig, ThemeConfig } from "react-select";

export function getSelectStyle<T>(themeString: string): StylesConfig<T> {
  const customStyle: StylesConfig<T> = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      color: state.isDisabled
        ? "#808080"
        : state.isFocused
        ? "#ffffff"
        : "#ffffff",
      borderColor: state.isDisabled
        ? "rgba(118, 118, 118, 0.3)"
        : state.isFocused
        ? "#464EB8"
        : "#A9A9A9",
      backgroundColor: themeString === "dark" ? "#292929" : "#ffffff",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected || state.isFocused
          ? "#464EB8"
          : themeString === "dark"
          ? "#292929"
          : "#ffffff",
      "&:hover": {
        backgroundColor: state.isSelected ? "#464EB8" : "#464EB8",
        color: state.isSelected ? "#ffffff" : "#ffffff",
      },
      color: state.isSelected
        ? "#ffffff"
        : themeString === "dark"
        ? "#ffffff"
        : "#292929",
      borderColor: themeString === "dark" ? "#292929" : "#ffffff",
    }),
    singleValue: (base, state) => ({
      ...base,
      color: state.isDisabled
        ? "#808080"
        : themeString === "dark"
        ? "#ffffff"
        : "#292929",
      borderColor: state.isDisabled ? "rgba(118, 118, 118, 0.3)" : undefined,
      //backgroundColor: themeString === 'dark' ? '#ffffff' : '#292929',
    }),
    noOptionsMessage: (base) => ({
      ...base,
      backgroundColor: themeString === "dark" ? "#292929" : "#ffffff",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FF0000",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: themeString === "dark" ? "#292929" : "#ffffff",
      color: themeString === "dark" ? "#ffffff" : "#292929",
    }),
    input: (base) => ({
      ...base,
      color: themeString === "dark" ? "#ffffff" : "#292929",
    }),
    multiValue: (base) => ({
      ...base,
      //color: themeString === 'dark' ? '#ffffff' : '#292929',
      backgroundColor: themeString === "dark" ? "#545454" : "#d9dade",
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: themeString === "dark" ? "#ffffff" : "#292929",
    }),
    multiValueRemove: (base) => ({
      ...base,
      backgroundColor: "#8b90d6",
      "&:hover": {
        backgroundColor: "#464EB8",
        color: "#ffffff",
      },
    }),
  };

  return customStyle;
}

export function getSelectTheme(themeString: string): ThemeConfig {
  const customTheme: ThemeConfig = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: "#464EB8",
      color: themeString === "dark" ? "#292929" : "#ffffff",
      text: themeString === "dark" ? "#292929" : "#ffffff",
    },
  });

  return customTheme;
}
