import { ContentHubItemElement } from "./ContentHubItemElement";


export enum PropertyType {
  Field = "Field",
  Item = "Item"
}

export enum SortingDirection {
  Ascending = "Ascending",
  Descending = "Descending"
}

export type PropertySort<T = unknown> = {
  propertyType: PropertyType.Field;
  propertyName: keyof T;
  direction: SortingDirection;
} |
{
  propertyType: PropertyType.Item;
  propertyName: keyof Omit<ContentHubItemElement, "fields">;
  direction: SortingDirection;
};
