import { Input, Field, Button, Spinner } from "@fluentui/react-components";
import {
  ChevronRightFilled,
  DeleteRegular,
  HistoryRegular,
  MoreHorizontal24Filled,
} from "@fluentui/react-icons";
import {
  useState,
  useContext,
  useEffect,
  ReactNode,
  PropsWithChildren,
} from "react";
import "../ContentHubTable/CustomTheme.scss";
import { TeamsFxContext } from "../../Context";
import { useDebouncedCallback } from "use-debounce";
import {
  Menu,
  MenuTrigger,
  ToolbarButton,
  MenuPopover,
  MenuList,
  MenuItem,
  MenuProps,
} from "@fluentui/react-components";

//New Table
import { Table, useTable, PagingPosition } from "ka-table";
import { kaPropsUtils } from "ka-table/utils";
import { Column } from "ka-table/models";
import { ICellTextProps, IPagingProps } from "ka-table/props";
import * as ContentHubService from "../../../Services/contentHub/ContentHubService";
import { ItemChangeSet } from "../../../Services/types/ContentHub/ContentHubItemElement";
import { ContentHubItemHistory } from "../ContentHubItemHistory/ContentHubItemHistory";
import { ContentHubListsElement } from "../../../Services/types/ContentHub/ContentHubListsElement";
import { updatePageIndex, updatePageSize } from "ka-table/actionCreators";
import { AxiosResponse } from "axios";
import { ConfirmationDialog } from "../../CommonComponents/ConfirmationDialog";
import {
  MessageBarCommon,
  MessageAlert,
} from "../../CommonComponents/MessageBar/MessageBar";

export const ContentHubTable = ({
  onItemClicked,
  onNext,
  searchTrigger,
  itemsPerPageChanged,
  canDeleteItems,
  hasMoreItems,
  isLoading,
  list,
  dataItems,
  tableColumns,
}: {
  onItemClicked: (item: unknown) => void;
  onNext: () => void;
  itemsPerPageChanged: (value: number) => void;
  searchTrigger?: (value: string) => void;
  canDeleteItems: boolean;
  hasMoreItems: boolean;
  isLoading: boolean;
  showEndMessage: boolean;
  list?: ContentHubListsElement;
  dataItems: unknown[];
  tableColumns: Column[];
}) => {
  const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
  const [pageSize, setPageSize] = useState<number>(15);
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
  const [isSearchable, setIsSearchable] = useState<boolean>(false);

  useEffect(() => {
    if (list) {
      setIsSearchable(list.fields.filter((f) => f.isSearchable).length !== 0);
    } else {
      setIsSearchable(false);
    }
  }, [list]);
  const table = useTable({
    onDispatch: (action, tableProps) => {
      if (action["type"] === "SelectSingleRow") {
        const selected = kaPropsUtils.getSelectedData(tableProps).pop();
        if (selected !== undefined) {
          onItemClicked(selected);
        }
      } else if (action["type"] === "UpdatePageSize") {
        if (action["pageSize"]) {
          setPageSize(action["pageSize"]);
          itemsPerPageChanged(action["pageSize"]);
        }
      } else if (action["type"] === "UpdatePageIndex") {
        if (action["pageIndex"] !== undefined && action["pageIndex"] !== null) {
          setSelectedPageIndex(action["pageIndex"]);
        }
      }
    },
  });

  const [itemHistoryOpen, setItemHistoryOpen] = useState(false);
  const [itemHistoryData, setItemHistoryData] = useState<ItemChangeSet[]>([]);
  const onCloseHistory = () => {
    setItemHistoryOpen(false);
    setItemHistoryData([]);
  };

  const getItemVersion = async (itemId: string) => {
    if (list !== null && list !== undefined) {
      const siteId = list.siteId;
      const listId = list.id;
      if (siteId && listId) {
        const data = (await ContentHubService.getItemVersions(
          siteId,
          listId,
          itemId,
          teamsUserCredential!
        )) as ItemChangeSet[];

        setItemHistoryOpen(true);
        setItemHistoryData(data);
      }
    }
  };

  //Delete Item
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [userDecision, setUserDecision] = useState<string | null>(null);
  const handleConfirmation = (decision: string) => {
    setUserDecision(decision);
    setOpenConfirmation(false);
  };

  const [itemToDelete, setItemToDelete] = useState<string | null>(null);
  useEffect(() => {
    if (itemToDelete !== null && userDecision === null) {
      setOpenConfirmation(true);
    }

    if (userDecision !== null && itemToDelete !== null) {
      if (userDecision === "Yes") {
        deleteItem(itemToDelete);
      } else {
        console.log("Item Not Deleted");
      }

      setUserDecision(null);
      setItemToDelete(null);
    }
  }, [itemToDelete, userDecision]);

  const [messages, setMessages] = useState<MessageAlert[]>([]);
  const deleteItem = async (itemId: string) => {
    if (list !== null && list !== undefined) {
      const siteId = list.siteId;
      const listId = list.id;

      if (siteId && listId) {
        const result = (await ContentHubService.deleteItem(
          siteId,
          listId,
          itemId,
          teamsUserCredential!
        )) as AxiosResponse;

        if (result.status === 200) {
          const messageAlert: MessageAlert = {
            title: "Success",
            body: "Item was deleted successfully",
            intent: "success",
            id: 200,
          };
          setMessages([...messages, messageAlert]);
          searchTrigger!("");
        } else {
          const messageAlertError: MessageAlert = {
            title: `Error: ${result.status}`,
            body: "There was an error when trying to delete item.",
            intent: "error",
            id: result.status,
          };

          setMessages([...messages, messageAlertError]);
        }
      }
    }
  };

  const dismissAlerts = (alerts: MessageAlert[]) => {
    const newArray = messages.filter((item) => !alerts.includes(item));
    setMessages(newArray);
  };

  //Search
  const searchCallback = useDebouncedCallback((inputValue) => {
    if (inputValue !== undefined) {
      searchTrigger!(inputValue);
    }
  }, 1000);

  const [openRowId, setOpenRowId] = useState<string | null>(null);
  const MenuForTable = ({ rowKeyValue }: ICellTextProps) => {
    const isOpen = openRowId === rowKeyValue;

    const onOpenChange: MenuProps["onOpenChange"] = (e, data) => {
      e.stopPropagation();
      setOpenRowId(data.open ? rowKeyValue : null);
    };

    return (
      <Menu open={isOpen} onOpenChange={onOpenChange}>
        <MenuTrigger>
          <ToolbarButton aria-label="More" icon={<MoreHorizontal24Filled />} />
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuItem
              icon={<HistoryRegular />}
              onClick={() => getItemVersion(rowKeyValue)}
            >
              Item History
            </MenuItem>
            {canDeleteItems && <MenuItem
              className="delete-item-button"
              icon={<DeleteRegular />}
              onClick={() => setItemToDelete(rowKeyValue)}
            >
              Delete
            </MenuItem>}
          </MenuList>
        </MenuPopover>
      </Menu>
    );
  };

  const pageButton = (
    page: number,
    text: string | number,
    props: PropsWithChildren<IPagingProps>
  ) => (
    <Button
      shape="circular"
      style={{ aspectRatio: 1, minWidth: "unset", height: "100%" }}
      size="small"
      appearance={props.pageIndex !== page ? "subtle" : "outline"}
      onClick={() => {
        props.dispatch(updatePageIndex(page));
      }}
      key={page}
    >
      {text}
    </Button>
  );

  useEffect(() => {
    table.changeProps({ columns: tableColumns, rowKeyField: "id" });
  }, [tableColumns]);
  return (
    <div className="flexfill nooverflow">
      {messages.length > 0 ? (
        <MessageBarCommon
          messageAlerts={messages}
          dismissMessages={dismissAlerts}
        />
      ) : (
        <div></div>
      )}

      {isSearchable && (
        <div className="searchInputDiv">
          <Field label="Search">
            <Input
              onChange={(event) => {
                searchCallback(event.target.value);
              }}
            />
          </Field>
        </div>
      )}
      {/* New Table */}
      <div
        className={
          "contenthubtable flexfill nooverflow " +
          (themeString === "dark" ? "custom-theme-dark" : "custom-theme-light")
        }
      >
        <Table
          table={table}
          columns={tableColumns}
          data={dataItems}
          paging={{
            enabled: true,
            pageIndex: selectedPageIndex,
            pageSize: pageSize,
            pageSizes: [10, 15, 25],
            position: PagingPosition.Bottom,
          }}
          rowKeyField={"id"}
          childComponents={{
            dataRow: {
              elementAttributes: () => ({
                onClick: (_event, extendedEvent) => {
                  if (extendedEvent.childProps.rowKeyField !== "menuClick") {
                    table.selectSingleRow(extendedEvent.childProps.rowKeyValue);
                  }
                },
              }),
            },
            cellText: {
              content: (props: React.PropsWithChildren<ICellTextProps>) => {
                switch (props.column.key) {
                  case "menuClick":
                    return <MenuForTable {...props} />;
                }
              },
            },
            pagingSizes: {
              content(props) {
                return (
                  <div
                    style={{
                      display: "grid",
                      gridGap: "0.5em",
                      gridAutoFlow: "column",
                      alignItems: "center",
                      gridAutoColumns: "max-content",
                    }}
                  >
                    {props.pageSizes?.map((size, index) => (
                      <Button
                        shape="circular"
                        style={{
                          aspectRatio: 1,
                          minWidth: "unset",
                          height: "100%",
                        }}
                        size="small"
                        appearance={
                          props.pageSize !== size ? "subtle" : "outline"
                        }
                        onClick={() => {
                          props.dispatch(updatePageSize(size));
                        }}
                        key={index}
                      >
                        {size}
                      </Button>
                    ))}
                  </div>
                );
              },
            },
            pagingPages: {
              content(props) {
                if (
                  props.pageIndex === undefined ||
                  props.pagesCount === undefined
                )
                  return <></>;
                const pagesCount: number = props.pagesCount;
                const pageIndex: number = props.pageIndex;
                let previousPage: ReactNode | undefined = undefined;
                let nextPage: ReactNode | undefined = undefined;
                const firstPage = pageButton(0, 1, props);
                const lastPage =
                  pagesCount > 1
                    ? pageButton(pagesCount - 1, pagesCount, props)
                    : undefined;
                const pagination: ReactNode[] = [];
                const startPage = pageIndex - 2;
                const endPage = pageIndex + 2;
                if (startPage > 2) {
                  previousPage = pageButton(startPage - 1, "...", props);
                }
                if (endPage < pagesCount - 2) {
                  nextPage = pageButton(endPage + 1, "...", props);
                }
                for (
                  let i = Math.max(1, startPage);
                  i <= Math.min(endPage, pagesCount - 2);
                  i++
                ) {
                  const btn = pageButton(i, i + 1, props);
                  pagination.push(btn);
                }
                return (
                  <div
                    style={{
                      display: "grid",
                      gridGap: "0.5em",
                      gridAutoFlow: "column",
                      alignItems: "center",
                      gridAutoColumns: "max-content",
                    }}
                  >
                    {firstPage}
                    {previousPage}
                    {pagination}
                    {nextPage}
                    {lastPage}
                    {hasMoreItems && pageIndex === pagesCount - 1 && (
                      <Button
                        shape="circular"
                        style={{
                          minWidth: "9em",
                          height: "100%",
                        }}
                        size="small"
                        icon={
                          isLoading ? (
                            <Spinner size="tiny" />
                          ) : (
                            <ChevronRightFilled />
                          )
                        }
                        onClick={() => {
                          onNext();
                          if (dataItems.length % pageSize === 0) {
                            setSelectedPageIndex((i) => i + 1);
                          }
                        }}
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading..." : "Load more"}
                      </Button>
                    )}
                  </div>
                );
              },
            },
          }}
          noData={{
            text: "No Data Found",
          }}
          loading={{
            enabled: isLoading,
            text: "Loading...",
          }}
        />
      </div>
      <div>
        <ContentHubItemHistory
          listDefinition={list!}
          itemChanges={itemHistoryData}
          historyOpen={itemHistoryOpen}
          onClosedDrawer={onCloseHistory}
        />
      </div>
      <div>
        <ConfirmationDialog
          title="Delete Item"
          message="Are you sure you want to delete the item?"
          openDialog={openConfirmation}
          actions={["Yes"]}
          choiceAction={(result) => handleConfirmation(result)}
        />
      </div>
    </div>
  );
};
