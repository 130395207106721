import {
  Button,
  DialogOpenChangeData,
  DialogOpenChangeEvent,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  OverlayDrawer,
  Spinner,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { useContext, useEffect, useState } from "react";
import * as ApprovalService from "../../../Services/approvals/ApprovalService";
import { ApprovalRequest } from "../../../Services/types/approvalRequest";
import { TeamsFxContext } from "../../Context";
import "./ApprovalDrawer.css";
import { ApprovalCard } from "./ApprovalCard";

export const ApprovalDrawerComponent = ({
  drawerOpen,
  approvalRequest,
  onDrawerClosed,
  userEmail,
  closeOnUpdate = false,
}: {
  drawerOpen: boolean;
  approvalRequest: ApprovalRequest | string | null;
  onDrawerClosed: (result: ApprovalRequest | null) => void;
  userEmail: string;
  closeOnUpdate: boolean;
}) => {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const [loadErrorFlag, setLoadErrorFlag] = useState<boolean>(false);
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [request, setRequest] = useState<ApprovalRequest | null>(null);

  useEffect(() => {
    if (drawerOpen === true) {
      setLoadErrorFlag(false);
      setIsWorking(true);
      setRequest(null);
      if (typeof approvalRequest === "string") {
        (async () => {
          try {
            if (!teamsUserCredential) {
              throw new Error("TeamsFx SDK is not initialized.");
            }

            const r = await ApprovalService.getApprovalItem(
              approvalRequest,
              teamsUserCredential
            );
            setRequest(r);
          } catch {
            setLoadErrorFlag(true);
          }
          setIsWorking(false);
        })();
      } else {
        setRequest(approvalRequest);
        setIsWorking(false);
      }
    }
  }, [drawerOpen]);

  const closeDrawer = (result: ApprovalRequest | null) => {
    if (isWorking) return;
    onDrawerClosed(result);
  };

  const onOpenChange = (
    _event: DialogOpenChangeEvent,
    data: DialogOpenChangeData
  ) => {
    if (data.open === false) {
      if (request != null) {
        closeDrawer({ ...request });
      } else {
        closeDrawer(null);
      }
    }
  };

  return (
    <div>
      {
        <OverlayDrawer
          size="medium"
          position="end"
          modalType="modal"
          open={drawerOpen}
          onOpenChange={onOpenChange}
        >
          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  disabled={isWorking}
                  onClick={() =>
                    closeDrawer(request != null ? { ...request } : null)
                  }
                />
              }
            >
              Request Details
            </DrawerHeaderTitle>
          </DrawerHeader>
          <DrawerBody>
            {loadErrorFlag && (
              <MessageBar intent="error">
                <MessageBarBody>
                  <MessageBarTitle>Error</MessageBarTitle>
                  Could not retrieve the request from the server.
                </MessageBarBody>
              </MessageBar>
            )}
            {request == null && !loadErrorFlag && (
              <div
                style={
                  request != null && !loadErrorFlag
                    ? undefined
                    : { display: "flex", justifyContent: "center" }
                }
              >
                <Spinner size="extra-large" label="Loading..." />
              </div>
            )}
            {request != null && (
              <ApprovalCard
                request={request}
                userEmail={userEmail}
                onUpdate={(r) => {
                  setRequest(r);
                  if (closeOnUpdate) closeDrawer(r);
                }}
              />
            )}
          </DrawerBody>
        </OverlayDrawer>
      }
    </div>
  );
};
