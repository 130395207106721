
export interface IContentHubSearchFilter {
  type: string;
}

export enum StringSearchTypes {
  IsExactly = "IsExactly",
  Contains = "Contains",
  StartsWith = "StartsWith",
  EndsWith = "EndsWith"
}

export class StringSearchFilter implements IContentHubSearchFilter {
  type: string;
  term: string;
  searchType: StringSearchTypes = StringSearchTypes.Contains;
  constructor(type: string, term: string, searchType?: StringSearchTypes) {
    this.type = type;
    this.term = term;
    if (searchType) {
      this.searchType = searchType;
    }
  }
}

export enum PersonSearchFields {
  Id = 1,
  UserPrincipalName = 2,
  Name = 4,
  Email = 8,
  Department = 16,
  JobTitle = 32,
  All = JobTitle | Department | Email | Name | UserPrincipalName | Id
}

export class PersonSearchFilter extends StringSearchFilter {
  fields: PersonSearchFields = PersonSearchFields.Name | PersonSearchFields.Email;
  constructor(
    term: string,
    searchType?: StringSearchTypes,
    fields?: PersonSearchFields
  ) {
    super("person", term, searchType);
    if (fields) {
      this.fields = fields;
    }
  }
}

export class PersonArraySearchFilter extends StringSearchFilter {
  fields: PersonSearchFields = PersonSearchFields.Name | PersonSearchFields.Email;
  constructor(
    term: string,
    searchType?: StringSearchTypes,
    fields?: PersonSearchFields
  ) {
    super("person[]", term, searchType);
    if (fields) {
      this.fields = fields;
    }
  }
}

export enum ComparableTypeSearchTypes {
  IsExactly = "IsExactly",
  IsLessThan = "IsLessThan",
  IsLessThanOrEqual = "IsLessThanOrEqual",
  IsGreaterThan = "IsGreaterThan",
  IsGreaterThanOrEqual = "IsGreaterThanOrEqual"
}

export class ComparableTypeSearchFilter implements IContentHubSearchFilter {
  type: string;
  value: unknown;
  searchType: ComparableTypeSearchTypes;

  constructor(type: string, value: unknown, searchType: ComparableTypeSearchTypes) {
    this.type = type;
    this.value = value;
    this.searchType = searchType;
  }
}
enum BooleanOperatorType {
  And = "And",
  Or = "Or"
}
export class BooleanOperatorSearchFilter implements IContentHubSearchFilter {
  type: string;
  filters: IContentHubSearchFilter[];
  operator: BooleanOperatorType;

  constructor(
    type: string,
    filters: IContentHubSearchFilter[],
    operator: BooleanOperatorType = BooleanOperatorType.And
  ) {
    this.type = type;
    this.filters = filters;
    this.operator = operator;
  }
}
