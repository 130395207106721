import {
  BearerTokenAuthProvider,
  createApiClient,
  TeamsUserCredential,
} from "@microsoft/teamsfx";
import config from "../../components/sample/lib/config";
import * as SharePointDriveService from "../sharepoint/SharePointDriveService";
import { ContentHubAttachments } from "../types/ContentHub/ContentHubAttachments";
import {
  ContentHubItemElement,
  ItemChangeSet,
} from "../types/ContentHub/ContentHubItemElement";
import { ContentHubListsElement } from "../types/ContentHub/ContentHubListsElement";
import { ContentHubApiCollection, ContentHubPaginatedCollection } from "../types/ContentHub/ContentHubPaginatedCollection";
import { ContentHubQueryPagination } from "../types/ContentHub/ContentHubQueryPagination";
import { SearchItemsRequest } from "../types/ContentHub/SearchItemsRequest";
import { ContentHubSiteElement } from "../types/ContentHub/ContentHubSiteElement";
import { ContentHubInsertItemRequest } from "../types/ContentHub/ContentHubInsertItemRequest";
import { ContentHubUpdateItemRequest } from "../types/ContentHub/ContentHubUpdateItemRequest";

const apiBaseUrl = config.apiEndpoint + "/api/contenthub/";

async function getToken(credential: TeamsUserCredential) {
  return (await credential.getToken(""))!.token;
}

function getApiClient(credential: TeamsUserCredential) {
  const apiClient = createApiClient(
    apiBaseUrl,
    new BearerTokenAuthProvider(() => getToken(credential))
  );
  return apiClient;
}

export async function getSites(
  pagination: ContentHubQueryPagination | undefined | null,
  teamsUserCredential: TeamsUserCredential
): Promise<ContentHubPaginatedCollection<ContentHubSiteElement>> {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const queryParamsObj: Record<string, string> = {};
    if (pagination?.MaxItems) {
      queryParamsObj["MaxItems"] = pagination?.MaxItems?.toString();
    }
    if (pagination?.ContinuationString) {
      queryParamsObj["ContinuationString"] = pagination?.ContinuationString;
    }
    const queryParams = new URLSearchParams(queryParamsObj);
    let url = `sites`;
    if (queryParams.size > 0) {
      url = `${url}?${queryParams.toString()}`;
    }
    const response = await apiClient.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getSite(
  siteId: string,
  teamsUserCredential: TeamsUserCredential
): Promise<ContentHubSiteElement> {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const response = await apiClient.get(`sites/${siteId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getLists(
  siteId: string,
  pagination: ContentHubQueryPagination | undefined | null,
  teamsUserCredential: TeamsUserCredential
): Promise<ContentHubPaginatedCollection<ContentHubListsElement>> {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const queryParamsObj: Record<string, string> = {};
    if (pagination?.MaxItems) {
      queryParamsObj["MaxItems"] = pagination?.MaxItems?.toString();
    }
    if (pagination?.ContinuationString) {
      queryParamsObj["ContinuationString"] = pagination?.ContinuationString;
    }
    const queryParams = new URLSearchParams(queryParamsObj);
    let url = `sites/${siteId}/lists`;
    if (queryParams.size > 0) {
      url = `${url}?${queryParams.toString()}`;
    }

    const response = await apiClient.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getList(
  siteId: string,
  listId: string,
  teamsUserCredential: TeamsUserCredential
): Promise<ContentHubListsElement> {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const response = await apiClient.get(`sites/${siteId}/lists/${listId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getItems(
  siteId: string,
  listId: string,
  searchRequest: SearchItemsRequest,
  pagination: ContentHubQueryPagination | undefined | null,
  viewId: string | undefined | null,
  teamsUserCredential: TeamsUserCredential
): Promise<ContentHubPaginatedCollection<ContentHubItemElement>> {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const queryParamsObj: Record<string, string> = {};
    if (pagination?.MaxItems) {
      queryParamsObj["MaxItems"] = pagination?.MaxItems?.toString();
    }
    if (pagination?.ContinuationString) {
      queryParamsObj["ContinuationString"] = pagination?.ContinuationString;
    }
    if (viewId) {
      queryParamsObj["viewId"] = viewId;
    }
    const queryParams = new URLSearchParams(queryParamsObj);
    let url = `sites/${siteId}/lists/${listId}/items/search`;
    if (queryParams.size > 0) {
      url = `${url}?${queryParams.toString()}`;
    }

    const response = await apiClient.post(url, searchRequest);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getItemVersions<T>(
  siteId: string,
  listId: string,
  itemId: string,
  teamsUserCredential: TeamsUserCredential
): Promise<ItemChangeSet[]> {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const response = await apiClient.get(
      `sites/${siteId}/lists/${listId}/items/${itemId}`
    );
    const item = (await response.data) as ContentHubItemElement<T>;

    return item.changesets;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function createItem<T>(
  siteId: string,
  listId: string,
  request: ContentHubInsertItemRequest,
  teamsUserCredential: TeamsUserCredential,
  driveId?: string,
  folderId?: string,
  files?: File[]
) {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    if (files) {
      const attachmentValue: ContentHubAttachments = {
        itemFolderId: "UPLOADING FILES",
        filenames: [],
      };

      request.fields["_attachments"] = attachmentValue;
    }

    const response = await apiClient.post(
      `sites/${siteId}/lists/${listId}/items`,
      request
    );
    const itemCreated = response.data as ContentHubItemElement<T>;

    //If there were attachments added
    if (files) {
      let itemFolderId = await uploadDocuments(
        driveId!,
        folderId!,
        itemCreated.id,
        files,
        teamsUserCredential
      );
      const fileName: string[] = files!.map((file: File) => file.name);

      if (itemFolderId) {
        itemFolderId = itemFolderId.replaceAll('"', "");

        const attachmentValue: ContentHubAttachments = {
          itemFolderId: itemFolderId.toString(),
          filenames: fileName?.length === 0 ? [] : fileName,
        };

        const updateRequest: ContentHubUpdateItemRequest = {
          fields: {
            _attachments: attachmentValue,
          },
          ignoreLock: false,
        };
        const itemWithAttachments = await updateItems(
          siteId,
          listId,
          itemCreated.id,
          updateRequest,
          teamsUserCredential,
          driveId,
          folderId
        );
        return itemWithAttachments;
      }
    }

    return itemCreated;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function updateItems(
  siteId: string,
  listId: string,
  itemId: string,
  request: ContentHubUpdateItemRequest,
  teamsUserCredential: TeamsUserCredential,
  driveId?: string,
  folderId?: string,
  files?: File[]
) {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    //If there are attachments
    const attachments = request.fields["_attachments"] as ContentHubAttachments;

    if (files) {
      const attachmentValue = attachments ?? {
        itemFolderId: "UPLOADING FILES",
        filenames: [],
      };
      const itemFolderId = await uploadDocuments(
        driveId!,
        folderId!,
        itemId,
        files!,
        teamsUserCredential
      );
      const attachDriveId = attachments.itemFolderId;

      //Update the attachment drive ID if there was none
      if (files && files.length > 0 && attachDriveId && itemFolderId) {
        const fileName: string[] = files!.map((file: File) => file.name);

        attachmentValue.itemFolderId = itemFolderId;
        attachmentValue.filenames ??= [];
        fileName.forEach((f) => attachmentValue.filenames!.concat(f));

        request.fields["_attachments"] = attachmentValue;
      }
    }

    const response = await apiClient.patch(
      `sites/${siteId}/lists/${listId}/items/${itemId}`,
      request
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function deleteItem(
  siteId: string,
  listId: string,
  itemId: string,
  teamsUserCredential: TeamsUserCredential
) {
  try {
    const apiClient = getApiClient(teamsUserCredential);
    const response = await apiClient.delete(
      `sites/${siteId}/lists/${listId}/items/${itemId}`
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getPermissions(
  teamsUserCredential: TeamsUserCredential
): Promise<ContentHubApiCollection<string>> {
  try {
    const apiClient = getApiClient(teamsUserCredential);
    const response = await apiClient.get(`permissions`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function uploadDocuments(
  driveId: string,
  folderId: string,
  itemId: string,
  files: File[],
  teamsUserCredential: TeamsUserCredential
) {
  return await SharePointDriveService.uploadFiles(
    driveId,
    folderId,
    itemId,
    files,
    teamsUserCredential
  );
}
