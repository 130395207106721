import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useState, useEffect, useContext } from "react";
import {
  SelectTabData,
  SelectTabEvent,
  Switch,
  Tab,
  TabList,
  TabValue,
  OverlayDrawer,
  ProgressBar,
  DialogOpenChangeEvent,
  DialogOpenChangeData,
  DrawerHeader,
  DrawerHeaderTitle,
  Button,
  DrawerBody,
  Field,
  Input,
  Label,
  Textarea,
  MessageBar,
  MessageBarTitle,
  MessageBarBody,
  Spinner,
  CounterBadge,
} from "@fluentui/react-components";
import { CommentNoteFilled, Dismiss24Regular } from "@fluentui/react-icons";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "@fluentui/react-datepicker-compat";

//Internal
import {
  DropDownData,
  dropDownToString,
} from "../../../../Services/types/General/DropDownData";
import { Counterparty } from "../../../../Services/types/General/Counterparty";
import { ContentHubItemElement } from "../../../../Services/types/ContentHub/ContentHubItemElement";
import {
  BadDebt,
  BadDebtSchema,
} from "../../../../Services/types/ContentHub/Credlt/BadDebt";
import { ContentHubSiteElement } from "../../../../Services/types/ContentHub/ContentHubSiteElement";
import { ContentHubListsElement } from "../../../../Services/types/ContentHub/ContentHubListsElement";
import useContentHubForm from "../../../../Hooks/useContentHubForm";
import "../BadDebt/BadDebt.css";
import { SelectPerson } from "../../../CommonComponents/selectPerson/SelectPerson";
import { SelectDropDownData } from "../../../CommonComponents/SelectDropDownData/SelectDropDownData";
import { SelectCounterparty } from "../../../CommonComponents/SelectCounterparty/SelectCounterparty";
import { formatDate } from "../../../../utils/utils";
import { NumericInput } from "../../../CommonComponents/NumericInput/NumericInput";
import { UserContext } from "../../../../Context/UserContext";
import * as UserService from "../../../../Services/general/UserService";
import { FileUploaderCommon } from "../../../CommonComponents/FileUploader/FileUploader";
import { ContentHubAttachments } from "../../../../Services/types/ContentHub/ContentHubAttachments";
import { ContentHubItemApprovals } from "../../../ContentHubComponents/ContentHubItemApprovals";
import {
  ApprovalRequest,
  ApprovalStatus,
} from "../../../../Services/types/approvalRequest";
import { ApprovalHistoryState } from "../../../../Services/types/approvalHistoryElement";
import {
  getApprovalHistory,
  getApprovalItem,
} from "../../../../Services/approvals/ApprovalService";
import { TeamsFxContext } from "../../../Context";

const defaultForm = {
  counterparty: "",
  counterpartyId: "",
  accountNumber: "",
  rmAccountStatus: "",
  collector: undefined,
  glNumber: "110505",
  electricity: 0,
  gas: 0,
  terminationValue: 0,
  writeOffTotal: 0,
  writeOffSummary: "",
  creditApprovalDetails: "",
  reasonCode: "",
  filingDate: null,
  eventRelated: false,
  eventDetails: "",
  salesPerson: undefined,
  rvp: undefined,
  region: "",
  counterpartyClass: "",
  sicCode: "",
  reserved: "",
  requestor: undefined,
  requestStatus: "Pending",
  accountingReview: null,
  accountingStatus: "Pending",
  accountingComments: "",
  journalEntryNumber: null,
  dateCompleted: null,
  _attachments: null,
};

export const FormBadDebt = ({
  site,
  list,
  itemDefinition,
  drawerOpen,
  onDrawerClosed,
}: {
  site: ContentHubSiteElement | undefined;
  list: ContentHubListsElement | undefined;
  itemDefinition: ContentHubItemElement<BadDebt> | null | undefined;
  drawerOpen: boolean;
  onDrawerClosed: (result: BadDebt | null, changesApplied: boolean) => void;
}) => {
  const { userInfo } = useContext(UserContext);
  const [title, setTitle] = useState<string>("New Bad Debt");
  const [editing, setEditing] = useState<boolean>(false);

  //Dropdown Options
  const [rmStatus, setrmStatus] = useState<DropDownData[]>([]);
  const [glOptions, setglOptions] = useState<DropDownData[]>([]);
  const [reasonCodeOptions, setReasonCodeOptions] = useState<DropDownData[]>(
    []
  );
  const [regionOptions, setRegionOptions] = useState<DropDownData[]>([]);
  const [reservedOptions, setReservedOptions] = useState<DropDownData[]>([]);
  const [accountingStatusOptions, setAccountingStatusOptions] = useState<
    DropDownData[]
  >([]);
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);

  //Drawer Methods
  const closeDrawer = (result: BadDebt | null) => {
    // if (isDirty && !isSubmitSuccessful) {
    //     alert("Close Without Saving?");
    // }
    onDrawerClosed(result, shouldRefresh);
    setShouldRefresh(false);
  };

  const onOpenChange = (
    _event: DialogOpenChangeEvent,
    data: DialogOpenChangeData
  ) => {
    if (data.open === false) {
      closeDrawer(null);
    }
  };

  //Drawer open
  useEffect(() => {
    if (drawerOpen === true) {
      if (userInfo !== undefined) {
        const user = UserService.userProfileToContentHubUser(userInfo);
        if (user !== undefined) {
          setValue("requestor", user);
        }
      }
      //Load Options for DropDowns
      setrmStatus(getOptions("rmAccountStatus"));
      setglOptions(getOptions("glNumber"));
      setReasonCodeOptions(getOptions("reasonCode"));
      setRegionOptions(getOptions("region"));
      setReservedOptions(getOptions("reserved"));
      setAccountingStatusOptions(getOptions("accountingStatus"));
      setItemForm(itemDefinition);
    } else {
      setEditing(false);
      reset(defaultForm as unknown as BadDebt);
      setSubmitStatus(null);
      setSelectedTab("request");
      setGas(0.0);
      setTermination(0.0);
      setElectricity(0.0);
      setItemForm(null);
    }
  }, [drawerOpen]);

  //Form Methods
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm<BadDebt>({
    mode: "all",
    resolver: yupResolver<BadDebt>(BadDebtSchema),
  });
  const status = getValues("requestStatus");
  const workflowStep = getValues("_workflowStep");
  const requestor = getValues("requestor");

  useEffect(() => {
    if (errors !== undefined && errors !== null) {
      console.log(errors);
    }
  }, [errors]);

  //Form Options
  const {
    item,
    setItemForm,
    reloadFiles,
    setReloadFiles,
    submitStatus,
    beingSubmitted,
    setSubmitStatus,
    submitHandler,
    getOptions,
  } = useContentHubForm({
    siteDefinition: site,
    listDefinition: list,
    itemDefinition: itemDefinition,
  });

  //Load Item Selected
  useEffect(() => {
    loadSelected();
  }, [item]);

  const loadSelected = async () => {
    if (item !== null && item !== undefined) {
      setTitle("Editing Bad Debt Request");
      setEditing(true);
      //Info Load
      const emptyAttachment: ContentHubAttachments = {
        itemFolderId: "",
        filenames: [],
      };

      const infoEdit: BadDebt = {
        counterparty: item.fields.counterparty.value,
        counterpartyId: item.fields.counterpartyId.value,
        accountNumber: item.fields.accountNumber.value,
        rmAccountStatus: item.fields.rmAccountStatus.value,
        collector: item.fields.collector.value,
        glNumber: item.fields.glNumber.value,
        electricity: item.fields.electricity.value,
        gas: item.fields.gas.value,
        terminationValue: item.fields.terminationValue.value,
        writeOffTotal: item.fields.writeOffTotal.value,
        writeOffSummary: item.fields.writeOffSummary.value,
        creditApprovalDetails: item.fields.creditApprovalDetails.value,
        reasonCode: item.fields.reasonCode.value,
        filingDate: item.fields.filingDate?.value
          ? new Date(item.fields.filingDate?.value)
          : null,
        eventRelated: item.fields.eventRelated.value,
        eventDetails: item.fields.eventDetails.value,
        salesPerson: item.fields.salesPerson.value,
        rvp: item.fields.rvp.value,
        region: item.fields.region.value,
        counterpartyClass: item.fields.counterpartyClass.value,
        sicCode: item.fields.sicCode.value,
        reserved: item.fields.reserved.value,
        requestor: item.fields.requestor.value,
        requestStatus: item.fields.requestStatus.value,
        accountingReview: item.fields.accountingReview?.value,
        accountingStatus: item.fields.accountingStatus.value,
        accountingComments: item.fields.accountingComments.value,
        journalEntryNumber: item.fields.journalEntryNumber!.value,
        dateCompleted: item.fields.dateCompleted?.value
          ? new Date(item.fields.dateCompleted?.value)
          : null,
        _attachments:
          item.fields._attachments !== undefined
            ? item.fields._attachments.value
            : emptyAttachment,
        _workflowStep:
          item.fields._workflowStep !== undefined
            ? item.fields._workflowStep.value
            : "",
      };
      reset(infoEdit);
      setReloadFiles(true);

      setGas(getValues("gas"));
      setElectricity(getValues("electricity"));
      setTermination(getValues("terminationValue"));
    } else {
      setTitle("New Bad Debt Request");
      reset(defaultForm as unknown as BadDebt);
      setReloadFiles(false);

      if (userInfo != undefined) {
        const user = UserService.userProfileToContentHubUser(userInfo);
        if (user !== undefined) {
          setValue("requestor", user);
        }
      }
    }
  };

  //Handle Submit of Form
  const [canSubmit, setCanSubmit] = useState<boolean>(true);
  const [canEditRequest, setCanEditRequest] = useState<boolean>(true);

  useEffect(() => {
    console.log(status);
    if (itemDefinition?.isLocked === true) {
      setCanSubmit(false);
      setCanEditRequest(false);
      return;
    }
    switch (status) {
      case "Pending":
        setCanSubmit(true);
        setCanEditRequest(true);
        break;

      case "Approved for Accounting Review":
        setCanSubmit(true);
        setCanEditRequest(false);
        break;

      case "Revision Required":
        setCanSubmit(true);
        setCanEditRequest(true);
        break;

      default:
        setCanSubmit(false);
        setCanEditRequest(false);
        break;
    }
  }, [status]);

  const submitForm: SubmitHandler<BadDebt> = async (data: BadDebt) => {
    setShouldRefresh(true);
    if (editing) {
      await submitHandler(data, item?.id, editing, filesToUpload ?? undefined);
      setFilesToUpload(undefined);
    } else {
      await submitHandler(data, null, editing, filesToUpload ?? undefined);
      setFilesToUpload(undefined);
    }
  };

  //Set Required For Filing Date
  const reasonCodeSelected = watch("reasonCode");
  const [filingDateRequired, setFilingDateRequired] = useState<boolean>(false);
  useEffect(() => {
    const optionSelected = dropDownToString(reasonCodeSelected);
    setFilingDateRequired(optionSelected === "BKY" ? true : false);
    trigger("filingDate", { shouldFocus: true });
  }, [reasonCodeSelected]);

  //Set Required for Event Details
  const eventRelated = watch("eventRelated");
  const [eventDetailsRequired, setEventDetailsRequired] =
    useState<boolean>(false);
  useEffect(() => {
    setEventDetailsRequired(eventRelated);
    trigger("eventDetails", { shouldFocus: true });
  }, [eventRelated]);

  //Calculate Write Off
  const [electricity, setElectricity] = useState<number>(0.0);
  const [gas, setGas] = useState<number>(0.0);
  const [termination, setTermination] = useState<number>(0.0);
  const [totalWriteOff, setTotalWriteOff] = useState<number>(0);

  const sumValue = electricity + +gas + +termination;

  useEffect(() => {
    setValue("electricity", electricity);
    setValue("gas", gas);
    setValue("terminationValue", termination);

    setTotalWriteOff(sumValue);
    setValue("writeOffTotal", sumValue);
  }, [sumValue]);

  //DropDowns Info Counterparties
  const defaultCounterparty: Counterparty = {
    name:
      itemDefinition?.fields.counterparty !== undefined
        ? itemDefinition?.fields.counterparty.value
        : "",
    sic:
      itemDefinition?.fields.sicCode !== undefined
        ? itemDefinition?.fields.sicCode.value
        : "",
    counterpartyClass:
      itemDefinition?.fields.counterpartyClass !== undefined
        ? itemDefinition?.fields.counterpartyClass.value
        : "",
    counterpartyId:
      itemDefinition?.fields.counterpartyId !== undefined
        ? +itemDefinition?.fields.counterpartyId.value
        : 0,
    status: "ACTIVE",
  };
  const handleCounterparty = (counterparty: Counterparty | undefined) => {
    if (counterparty !== undefined) {
      setValue("counterparty", counterparty.name);
      setValue("counterpartyId", counterparty.counterpartyId.toString());
      setValue("sicCode", counterparty.sic);
      setValue("counterpartyClass", counterparty.counterpartyClass);
    }
  };

  //Handle Set to Submit
  const submitRequestToWorkflow = (newStatus: string) => {
    setValue("requestStatus", newStatus);
    submitForm(getValues());
  };

  //Files
  const [filesToUpload, setFilesToUpload] = useState<File[] | undefined>(
    undefined
  );
  const handleFiles = (files: File[]) => {
    setFilesToUpload(files);
  };

  //Tabs
  const [selectedTab, setSelectedTab] = useState<TabValue>("request");
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedTab(data.value);
  };

  const [approvalItems, setApprovalItems] = useState<ApprovalRequest[]>([]);
  const [historyItems, setHistoryItems] = useState<ApprovalHistoryState[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { teamsUserCredential } = useContext(TeamsFxContext);

  useEffect(() => {
    if (!teamsUserCredential) return;
    if (!item) return;
    setIsLoading(true);
    (async () => {
      const approvalHistory = await getApprovalHistory(
        `${item?.siteId}/${item?.listId}/${item?.id}`,
        teamsUserCredential
      );
      setHistoryItems(approvalHistory);

      const uniqueIds = new Set(approvalHistory.map((h) => h.requestId));
      const items: ApprovalRequest[] = [];
      const array: string[] = [];
      if (approvalHistory.length == 0) {
        setApprovalItems([]);
      }

      uniqueIds.forEach((v) => array.push(v));
      for (const id of array) {
        items.push(await getApprovalItem(id, teamsUserCredential));
        setApprovalItems(items);
      }
      setIsLoading(false);
    })();
  }, [item]);

  return (
    <div key="badDebtFormDiv">
      <OverlayDrawer
        size="large"
        position="end"
        modalType="alert"
        open={drawerOpen}
        onOpenChange={onOpenChange}
      >
        {submitStatus !== null ? (
          submitStatus === true ? (
            <div className="submitMessage">
              <MessageBar key="info-attachments" intent="success">
                <MessageBarBody>
                  <MessageBarTitle>Submit Success</MessageBarTitle>
                  Information submitted succesfully to database.
                </MessageBarBody>
              </MessageBar>
            </div>
          ) : (
            <div className="submitMessage">
              <MessageBar key="info-attachments" intent="error">
                <MessageBarBody>
                  <MessageBarTitle>Submit Error</MessageBarTitle>
                  Unexpected error when trying to submit information
                </MessageBarBody>
              </MessageBar>
            </div>
          )
        ) : (
          <></>
        )}

        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular></Dismiss24Regular>}
                onClick={() => {
                  closeDrawer(null);
                }}
              ></Button>
            }
          >
            {title}
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          {drawerOpen && (
            <form onSubmit={handleSubmit(submitForm)}>
              <div className="submitbutton">
                {beingSubmitted === false ? (
                  <Button
                    // disabled={!isValid}
                    appearance="primary"
                    type="submit"
                    disabled={!canSubmit}
                  >
                    Save
                  </Button>
                ) : (
                  <div style={{ marginTop: "10px" }}>
                    <Field
                      validationMessage="Please Wait... Saving and Uploading Files."
                      validationState="none"
                    >
                      <ProgressBar />
                    </Field>
                  </div>
                )}
              </div>

              {editing && (
                <div className="statusDiv">
                  <div className="status">
                    <h2>
                      <Label
                        htmlFor="statusId"
                        style={{ color: "" }}
                        className="statusLabel"
                      >
                        Status : {status}
                      </Label>
                    </h2>
                    {status === "Request Completed" && (
                      <div style={{ marginTop: "10px" }}>
                        <Label>
                          Date Completed:
                          {formatDate(
                            item?.fields?.dateCompleted?.value
                              ? item.fields.dateCompleted.value
                              : new Date()
                          )}
                        </Label>
                      </div>
                    )}
                    {status === "Pending" && (
                      <Button
                        disabled={userInfo?.id !== requestor.id}
                        appearance="primary"
                        onClick={() =>
                          submitRequestToWorkflow("Ready to submit")
                        }
                      >
                        Submit Request
                      </Button>
                    )}

                    {status === "Revision Required" &&
                      workflowStep == "managerRequiredMoreInfoSentEmail" && (
                        <Button
                          appearance="primary"
                          disabled={userInfo?.id !== requestor.id}
                          onClick={() =>
                            submitRequestToWorkflow(
                              "Ready for Manager of Credit Risk Review"
                            )
                          }
                        >
                          Ready for Manager of Credit Risk Review
                        </Button>
                      )}

                    {status === "Revision Required" &&
                      workflowStep ==
                        "managingDirectorRequiredMoreInfoSentEmail" && (
                        <Button
                          disabled={userInfo?.id !== requestor.id}
                          appearance="primary"
                          onClick={() =>
                            submitRequestToWorkflow(
                              "Ready for Managing Director of Credit Risk Review"
                            )
                          }
                        >
                          Ready for Managing Director of Credit Risk Review
                        </Button>
                      )}
                  </div>
                </div>
              )}

              <TabList selectedValue={selectedTab} onTabSelect={onTabSelect}>
                <Tab id="request" value="request">
                  Request
                </Tab>
                {editing &&
                  (status === "Approved for Accounting Review" ||
                    status === "Request Completed") && (
                    <Tab id="accounting" value="accounting">
                      Accounting
                    </Tab>
                  )}
                {editing && (
                  <Tab
                    id="approvals"
                    value="approvals"
                    icon={isLoading ? <Spinner size="extra-tiny" /> : undefined}
                  >
                    Approvals
                    {!isLoading &&
                      approvalItems.filter((a) => a.comment).length > 0 && (
                        <>
                          {" "}
                          <CommentNoteFilled />
                        </>
                      )}
                    {!isLoading && (
                      <>
                        {" "}
                        <CounterBadge count={approvalItems.length} />
                      </>
                    )}
                  </Tab>
                )}
              </TabList>

              {selectedTab === "approvals" && (
                <div style={{ marginTop: "10px" }}>
                  {/* <ApprovalHistory correlationKey={correlationKey} /> */}
                  <ContentHubItemApprovals
                    siteId={site!.id}
                    listId={list!.id}
                    itemId={item!.id}
                    status={ApprovalStatus.All}
                    approvals={approvalItems}
                    history={historyItems}
                  />
                </div>
              )}

              {selectedTab === "request" && (
                <div className="requestArea">
                  {/* CounterpartyName */}
                  <div className="counterpartyDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="counterparty" />
                    </div>
                    <SelectCounterparty
                      disabled={!canEditRequest}
                      label="Counterparty"
                      showInfo={true}
                      defaultCounterparty={defaultCounterparty}
                      control={control}
                      name="counterparty"
                      handleCounterpartySelection={handleCounterparty}
                      required={true}
                    />
                  </div>

                  {/* Account Number */}
                  <div className="accountNumberDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="accountNumber" />
                    </div>
                    <Field label="Account Number" required>
                      <Controller
                        name="accountNumber"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            disabled={!canEditRequest}
                            type="text"
                          />
                        )}
                      />
                    </Field>
                  </div>

                  {/* RM Account Status */}
                  <div className="rmAccountStatusDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="rmAccountStatus" />
                    </div>
                    <SelectDropDownData
                      disabled={!canEditRequest}
                      label="RM Account Status"
                      options={rmStatus}
                      control={control}
                      name="rmAccountStatus"
                      required={true}
                    />
                  </div>

                  {/* Collector */}
                  <div className="collectorDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="collector" />
                    </div>
                    <SelectPerson
                      disabled={!canEditRequest}
                      label="Collector"
                      group="SGG_NES_CREDITRISK"
                      control={control}
                      name="collector"
                      required={true}
                    />
                  </div>

                  {/* GL Number */}
                  <div className="glNumberDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="glNumber" />
                    </div>
                    <SelectDropDownData
                      disabled={!canEditRequest}
                      label="GL Number"
                      options={glOptions}
                      control={control}
                      name="glNumber"
                      required={true}
                    />
                  </div>

                  {/* Electricity */}
                  <div className="electricityDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="electricity" />
                    </div>
                    <NumericInput
                      disabled={!canEditRequest}
                      label="Electricity"
                      control={control}
                      name="electricity"
                      defaultValue={electricity}
                      handleChange={(data) => setElectricity(data)}
                      isMoney={true}
                    />
                  </div>

                  {/* Gas */}
                  <div className="gasDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="gas" />
                    </div>
                    <NumericInput
                      disabled={!canEditRequest}
                      label="Gas"
                      control={control}
                      name="gas"
                      defaultValue={gas}
                      handleChange={(data) => setGas(data)}
                      isMoney={true}
                    />
                  </div>

                  {/* Termination Value */}
                  <div className="terminationValueDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="terminationValue" />
                    </div>
                    <NumericInput
                      disabled={!canEditRequest}
                      label="Termination Value"
                      control={control}
                      name="terminationValue"
                      defaultValue={termination}
                      handleChange={(data) => setTermination(data)}
                      isMoney={true}
                    />
                  </div>

                  {/* Write Off Total */}
                  <div className="writeOffTotalDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="writeOffTotal" />
                    </div>
                    <Field label="Write Off Total">
                      <Label>
                        <b>
                          {Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(totalWriteOff)}
                        </b>
                      </Label>
                    </Field>
                  </div>

                  {/* Write Off Summary */}
                  <div className="writeOffSummaryDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="writeOffSummary" />
                    </div>
                    <Field label="Write Off Summary" required>
                      <Controller
                        name="writeOffSummary"
                        control={control}
                        render={({ field }) => (
                          <Textarea
                            disabled={!canEditRequest}
                            {...field}
                            className="writeOffSummaryInput"
                            key="writeOffSummaryKey"
                            rows={3}
                          />
                        )}
                      />
                    </Field>
                  </div>

                  {/* Credit Approval Details */}
                  <div className="creditApprovalDetailsDiv">
                    <div className="errorMessage">
                      <ErrorMessage
                        errors={errors}
                        name="creditApprovalDetails"
                      />
                    </div>
                    <Field label="Credit Approval Details" required>
                      <Controller
                        name="creditApprovalDetails"
                        control={control}
                        render={({ field }) => (
                          <Textarea
                            disabled={!canEditRequest}
                            {...field}
                            className="creditApprovalDetailsInput"
                            key="creditApprovalDetailsKey"
                            rows={3}
                          />
                        )}
                      />
                    </Field>
                  </div>

                  {/* Reason Code */}
                  <div className="reasonCodeDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="reasonCode" />
                    </div>
                    <SelectDropDownData
                      disabled={!canEditRequest}
                      label="Reason Code"
                      options={reasonCodeOptions}
                      control={control}
                      name="reasonCode"
                      required={true}
                    />
                  </div>

                  {/* Filing Date */}
                  <div className="filingDateDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="filingDate" />
                    </div>
                    <Field label="Filing Date" required={filingDateRequired}>
                      <Controller
                        name="filingDate"
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                          <DatePicker
                            //onOpenChange={onOpen}
                            disabled={!canEditRequest}
                            onSelectDate={onChange}
                            ref={ref}
                            placeholder="Select a date..."
                            value={value}
                            formatDate={(value) => {
                              return value !== undefined
                                ? formatDate(value)
                                : "";
                            }}
                          />
                        )}
                      />
                    </Field>
                  </div>

                  {/* Event Related */}
                  <div className="eventRelatedDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="eventRelated" />
                    </div>
                    <Field label="Event Related">
                      <Controller
                        name="eventRelated"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Switch
                            disabled={!canEditRequest}
                            name="name"
                            checked={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Field>
                  </div>

                  {/* Event Details */}
                  <div className="eventDetailsDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="eventDetails" />
                    </div>
                    <Field
                      label="Event Details"
                      required={eventDetailsRequired}
                    >
                      <Controller
                        name="eventDetails"
                        control={control}
                        render={({ field }) => (
                          <Textarea
                            disabled={!canEditRequest}
                            {...field}
                            className="eventDetailsInput"
                            key="eventDetailsKey"
                            rows={3}
                          />
                        )}
                      />
                    </Field>
                  </div>

                  {/* Salesperson */}
                  <div className="salespersonDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="salesPerson" />
                    </div>
                    <SelectPerson
                      disabled={!canEditRequest}
                      label="Sales Person"
                      control={control}
                      group="SGG_NES_SALES"
                      name="salesPerson"
                      required={true}
                    />
                  </div>

                  {/* RVP */}
                  <div className="rvpDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="rvp" />
                    </div>
                    <SelectPerson
                      disabled={!canEditRequest}
                      label="RVP"
                      control={control}
                      group="SGG_NES_SALES"
                      name="rvp"
                      required={true}
                    />
                  </div>

                  {/* Region */}
                  <div className="regionDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="region" />
                    </div>
                    <SelectDropDownData
                      disabled={!canEditRequest}
                      label="Region"
                      options={regionOptions}
                      control={control}
                      name="region"
                      required={true}
                    />
                  </div>

                  {/* Reserved */}
                  <div className="reservedDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="reserved" />
                    </div>
                    <SelectDropDownData
                      disabled={!canEditRequest}
                      label="Reserved"
                      options={reservedOptions}
                      control={control}
                      name="reserved"
                      required={true}
                    />
                  </div>

                  {/* Attachments */}
                  <div className="attachmentsDiv">
                    <h2>Attachments</h2>
                    <FileUploaderCommon
                      disabled={!canEditRequest}
                      reload={reloadFiles}
                      attachment={
                        reloadFiles && item?.fields._attachments !== undefined
                          ? item?.fields._attachments.value
                          : null
                      }
                      listDefinition={list!}
                      filesOnForm={handleFiles}
                      fileTypes={["pdf", "txt", "xlsx", "xls"]}
                    />
                  </div>
                </div>
              )}
              {selectedTab === "accounting" && (
                <div className="accountingArea">
                  {/* Accounting Reviewd By */}
                  <div className="accountingReviewDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="accountingReview" />
                    </div>
                    <SelectPerson
                      disabled={status !== "Approved for Accounting Review"}
                      label="Accounting Reviewed By"
                      control={control}
                      name="accountingReview"
                      group="SGG_NES_COMMODITYACCOUNTING"
                      required={true}
                    />
                  </div>

                  {/* Accounting Status */}
                  <div className="accountingStatusDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="accountingStatus" />
                    </div>
                    <SelectDropDownData
                      disabled={status !== "Approved for Accounting Review"}
                      label="Accounting Review Status"
                      options={accountingStatusOptions}
                      control={control}
                      name="accountingStatus"
                      required={true}
                    />
                  </div>

                  {/* Accounting Comments */}
                  <div className="accountingCommentsDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="accountingComments" />
                    </div>
                    <Field label="Accounting Comments">
                      <Controller
                        name="accountingComments"
                        control={control}
                        render={({ field }) => (
                          <Textarea
                            disabled={
                              status !== "Approved for Accounting Review"
                            }
                            {...field}
                            className="accountingCommentsInput"
                            key="accountingCommentsKey"
                            rows={3}
                          />
                        )}
                      />
                    </Field>
                  </div>

                  {/* Journal Entry Number */}
                  {/* <div className="journalEntryDateDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="journalEntryNumber" />
                    </div>
                    <Field label="Journal Entry Number">
                      <Controller
                        name="journalEntryNumber"
                        control={control}
                        render={({ field }) => (
                          <Input {...field} type="number" min={0} />
                        )}
                      />
                    </Field>
                  </div> */}

                  {/* Date Completed */}
                  <div className="completedDateDiv"></div>
                </div>
              )}
            </form>
          )}
        </DrawerBody>
      </OverlayDrawer>
    </div>
  );
};
