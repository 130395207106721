import { useCallback, useContext, useEffect, useState } from "react";
import { ContentHubListsElement } from "../Services/types/ContentHub/ContentHubListsElement";
import * as ContentHubService from "../Services/contentHub/ContentHubService";
import { TeamsFxContext } from "../components/Context";
import { ContentHubContext } from "../Context/ContentHubContext";
import { generateSearchRequest } from "../utils/utils";
import { ContentHubItemElement } from "../Services/types/ContentHub/ContentHubItemElement";

export default function useContentHubList(
  siteId: string,
  listId: string,
  viewId?: string,
  resultsPerPage?: number
) {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const contentHubContext = useContext(ContentHubContext);
  useEffect(() => {
    (async () => {
      if (siteId !== undefined && listId !== undefined) {
        setContinuationString(undefined);
        setShouldLoadItems(false);
        setIsLoading(false);
        setShouldRepopulate(true);
        setListDefinition(null);
      }
    })();
  }, [siteId, listId]);

  const [shouldRepopulate, setShouldRepopulate] = useState<boolean>(true);
  const [itemsPerPage, setItemsPerPage] = useState<number>(25);
  useEffect(() => {
    setItemsPerPage(resultsPerPage ?? 25);
  }, [resultsPerPage]);
  useEffect(() => {
    if (shouldRepopulate) {
      const listData = contentHubContext.lists[siteId].find(
        (l) => l.id === listId
      );
      setItems([]);
      setContinuationString(undefined);
      setShouldLoadItems(true);
      setIsLoading(false);
      setShouldRepopulate(false);
      setListDefinition(listData ?? null);
    }
  }, [listId, shouldRepopulate, siteId, contentHubContext]);

  const [continuationString, setContinuationString] = useState<
    string | undefined
  >(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const [listDefinition, setListDefinition] =
    useState<ContentHubListsElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shouldLoadItems, setShouldLoadItems] = useState<boolean>(false);
  const [items, setItems] = useState<ContentHubItemElement[]>([]);

  useEffect(() => {
    setItems([]);
  }, [listDefinition]);

  useEffect(() => {
    if (shouldLoadItems && listDefinition !== null && !isLoading) {
      setIsLoading(true);
      (async () => {
        const itemsResponse = await ContentHubService.getItems(
          listDefinition.siteId,
          listDefinition.id,
          generateSearchRequest(listDefinition, searchTerm),
          { ContinuationString: continuationString ?? undefined, MaxItems: itemsPerPage },
          viewId,
          teamsUserCredential!
        );
        setCurrentPage((c) => c + 1);
        setContinuationString(itemsResponse.continuationString ?? undefined);
        setItems((i) => i.concat(itemsResponse.items));
        setIsLoading(false);
        setShouldLoadItems(false);
      })();
    }
  }, [isLoading, shouldLoadItems, listDefinition, viewId]);

  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const LoadMoreItems = useCallback(() => {
    setShouldLoadItems(true);
  }, []);

  const ClearItems = useCallback(() => {
    setShouldRepopulate(true);
  }, []);

  const SearchItems = useCallback((searchTerm: string) => {
    if ((searchTerm?.trim() ?? "") === "") {
      setSearchTerm(null);
    } else {
      setSearchTerm(searchTerm);
    }
    setContinuationString(undefined);
    setItems([]);
    setShouldLoadItems(true);
  }, []);

  const [hasMoreItems, setHasMoreItems] = useState(true);
  useEffect(() => {
    setHasMoreItems(currentPage === 0 || continuationString != null);
  }, [currentPage, continuationString]);

  return {
    listDefinition,
    items,
    isLoading,
    hasMoreItems,
    LoadMoreItems,
    ClearItems,
    SearchItems,
  };
}
