export interface Counterparty {
    counterpartyId: number,
    status: string,
    name: string,
    sic: string,
    counterpartyClass: string
}

export const counterpartyToSingleValue = (value: string | Counterparty, field: string) => {
    if(typeof value === "string"){
        return value;
    }
    const currentValue: Counterparty = value;

    switch(field){
        case "name": {
            return currentValue.name;
        }
        
        case "counterpartyId":{
            return currentValue.counterpartyId;
        }
    }
}