import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useState, useEffect, useContext } from "react";
import {
  SelectTabData,
  SelectTabEvent,
  ProgressBar,
  Tab,
  TabList,
  TabValue,
  OverlayDrawer,
  DialogOpenChangeEvent,
  DialogOpenChangeData,
  DrawerHeader,
  DrawerHeaderTitle,
  Button,
  DrawerBody,
  Field,
  Input,
  Textarea,
  MessageBar,
  MessageBarTitle,
  MessageBarBody,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";

//Internal
import { DropDownData } from "../../../../Services/types/General/DropDownData";
import { ContentHubSiteElement } from "../../../../Services/types/ContentHub/ContentHubSiteElement";
import { ContentHubListsElement } from "../../../../Services/types/ContentHub/ContentHubListsElement";
import { ContentHubItemElement } from "../../../../Services/types/ContentHub/ContentHubItemElement";
import { UserContext } from "../../../../Context/UserContext";
import {
  SqlQueryRequest,
  SqlQueryRequestSchema,
} from "../../../../Services/types/ContentHub/IT/SqlQueryRequests";
import useContentHubForm from "../../../../Hooks/useContentHubForm";
import { SelectPerson } from "../../../CommonComponents/selectPerson/SelectPerson";
import { SelectDropDownData } from "../../../CommonComponents/SelectDropDownData/SelectDropDownData";
import { FileUploaderCommon } from "../../../CommonComponents/FileUploader/FileUploader";
import * as UserService from "../../../../Services/general/UserService";

const defaultForm = {
  targetServer: "",
  sourceServer: "",
  database: "",
  request: "",
  approvers: null,
  requestor: null,
  ticketNumber: "",
  status: "Pending",
  businessUnit: "Solutions",
  title: "",
  _attachments: null,
};

export const SqlQueryRequestForm = ({
  site,
  list,
  itemDefinition,
  drawerOpen,
  onDrawerClosed,
}: {
  site: ContentHubSiteElement | undefined;
  list: ContentHubListsElement | undefined;
  itemDefinition: ContentHubItemElement<SqlQueryRequest> | null | undefined;
  drawerOpen: boolean;
  onDrawerClosed: (
    result: SqlQueryRequest | null,
    changesApplied: boolean
  ) => void;
}) => {
  //UI
  const { userInfo } = useContext(UserContext);
  const [title, setTitle] = useState<string>("New Balance Transfer");
  const [editing, setEditing] = useState<boolean>(false);
  const [isLoading] = useState<boolean>();

  //Dropdown Options
  const [sqlStatus, setSqlStatus] = useState<DropDownData[]>([]);
  const [sqlBusiness, setSqlBusiness] = useState<DropDownData[]>([]);
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);

  //Drawer Methods
  const closeDrawer = (result: SqlQueryRequest | null) => {
    // if (isDirty && !isSubmitSuccessful) {
    //     alert("Close Without Saving?");
    // }
    onDrawerClosed(result, shouldRefresh);
    setShouldRefresh(false);
  };

  const onOpenChange = (
    _event: DialogOpenChangeEvent,
    data: DialogOpenChangeData
  ) => {
    if (data.open === false) {
      closeDrawer(null);
    }
  };

  //Drawer Open
  useEffect(() => {
    if (drawerOpen === true) {
      if (userInfo != undefined) {
        const user = UserService.userProfileToContentHubUser(userInfo);
        if (user !== undefined) {
          setValue("requestor", user);
        }
      }

      //Load Options for DropDowns
      setSqlBusiness(getOptions("businessUnit"));
      setSqlStatus(getOptions("status"));
      setItemForm(itemDefinition);
    } else {
      setEditing(false);
      reset(defaultForm as unknown as SqlQueryRequest);
      setReloadFiles(false);
      setSubmitStatus(null);
      setItemForm(null);
    }
  }, [drawerOpen]);

  //Form Methods
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<SqlQueryRequest>({
    mode: "all",
    resolver: yupResolver<SqlQueryRequest>(SqlQueryRequestSchema),
  });

  //Form Options
  const {
    item,
    setItemForm,
    submitStatus,
    reloadFiles,
    setReloadFiles,
    beingSubmitted,
    setSubmitStatus,
    submitHandler,
    getOptions,
  } = useContentHubForm<SqlQueryRequest>({
    siteDefinition: site,
    listDefinition: list,
  });

  //Load Item Selected
  useEffect(() => {
    loadSelected();
  }, [item]);

  const loadSelected = () => {
    if (item !== null && item !== undefined) {
      setTitle("Editing Sql Query Request");
      setEditing(true);
      //Info Load
      const infoEdit: SqlQueryRequest = {
        targetServer: item.fields.targetServer.value,
        sourceServer: item.fields.sourceServer.value,
        database: item.fields.database.value,
        request: item.fields.request.value,
        approvers: item.fields.approvers.value,
        requestor: item.fields.requestor.value,
        ticketNumber: item.fields.ticketNumber.value,
        status: item.fields.status.value,
        businessUnit: item.fields.businessUnit.value,
        title: item.fields.title.value,
        _attachments: item.fields._attachments.value,
        _workflowStep: item.fields._workflowStep.value,
      };
      reset(infoEdit);
      setReloadFiles(true);
    } else {
      setTitle("New Sql Query Request");
      setEditing(false);
      reset(defaultForm as unknown as SqlQueryRequest);
    }
  };

  useEffect(() => {
    if (errors !== undefined && errors !== null) {
      console.log(errors);
    }
  }, [errors]);

  const submitForm: SubmitHandler<SqlQueryRequest> = async (
    data: SqlQueryRequest
  ) => {
    setShouldRefresh(true);
    if (editing) {
      await submitHandler(data, item?.id, editing, filesToUpload ?? undefined);
      setFilesToUpload(null);
    } else {
      await submitHandler(data, null, editing, filesToUpload ?? undefined);
      setFilesToUpload(null);
    }
  };

  //Files
  const [filesToUpload, setFilesToUpload] = useState<File[] | null>(null);
  const handleFiles = (files: File[]) => {
    setFilesToUpload(files);
  };

  //Tabs
  const [selectedTab, setSelectedTab] = useState<TabValue>("request");
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedTab(data.value);
  };

  return (
    <div key="sqlQueryRequestFormDiv">
      <OverlayDrawer
        size="large"
        position="end"
        modalType="modal"
        open={drawerOpen}
        onOpenChange={onOpenChange}
      >
        {submitStatus !== null ? (
          submitStatus == true ? (
            <div className="submitMessage">
              <MessageBar key="info-attachments" intent="success">
                <MessageBarBody>
                  <MessageBarTitle>Submit Success</MessageBarTitle>
                  Information submitted succesfully to database.
                </MessageBarBody>
              </MessageBar>
            </div>
          ) : (
            <div className="submitMessage">
              <MessageBar key="info-attachments" intent="error">
                <MessageBarBody>
                  <MessageBarTitle>Submit Error</MessageBarTitle>
                  Unexpected error when trying to submit information
                </MessageBarBody>
              </MessageBar>
            </div>
          )
        ) : (
          <div></div>
        )}
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular></Dismiss24Regular>}
                onClick={() => {
                  closeDrawer(null);
                }}
              ></Button>
            }
          >
            {title}
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>
          {drawerOpen && !isLoading ? (
            <form onSubmit={handleSubmit(submitForm)}>
              {/* Tabs */}
              <TabList selectedValue={selectedTab} onTabSelect={onTabSelect}>
                <Tab id="request" value="request">
                  Request
                </Tab>
              </TabList>

              {selectedTab == "request" && (
                <div className="requestSqlArea">
                  {/* Title */}
                  <div className="SqlTitle">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="title"></ErrorMessage>
                    </div>
                    <Field label="Title">
                      <Controller
                        name="title"
                        control={control}
                        render={({ field }) => <Input {...field} />}
                      />
                    </Field>
                  </div>

                  {/* TargetServer */}
                  <div className="SqlTargetServer">
                    <div className="errorMessage">
                      <ErrorMessage
                        errors={errors}
                        name="targetServer"
                      ></ErrorMessage>
                    </div>
                    <Field label="Target Server">
                      <Controller
                        name="targetServer"
                        control={control}
                        render={({ field }) => <Input {...field} />}
                      />
                    </Field>
                  </div>

                  {/* SourceServer */}
                  <div className="SqlSourceServer">
                    <div className="errorMessage">
                      <ErrorMessage
                        errors={errors}
                        name="sourceServer"
                      ></ErrorMessage>
                    </div>
                    <Field label="Source Server">
                      <Controller
                        name="sourceServer"
                        control={control}
                        render={({ field }) => <Input {...field} />}
                      />
                    </Field>
                  </div>

                  {/* Database */}
                  <div className="SqlDatabase">
                    <div className="errorMessage">
                      <ErrorMessage
                        errors={errors}
                        name="database"
                      ></ErrorMessage>
                    </div>
                    <Field label="Database">
                      <Controller
                        name="database"
                        control={control}
                        render={({ field }) => <Input {...field} />}
                      />
                    </Field>
                  </div>

                  {/* Request */}
                  <div className="SqlRequest">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="request" />
                    </div>
                    <Field label="Request">
                      <Controller
                        name="request"
                        control={control}
                        render={({ field }) => <Textarea {...field} rows={3} />}
                      />
                    </Field>
                  </div>

                  {/* Approvers */}
                  <div className="SqlApprovers">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="approvers" />
                    </div>
                    <SelectPerson
                      label="Approvers"
                      control={control}
                      name="approvers"
                      multiple={true}
                    />
                  </div>

                  {/* Requestor */}
                  <div className="SqlRequestor">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="requestor" />
                    </div>
                    <SelectPerson
                      label="Requestor"
                      control={control}
                      name="requestor"
                    />
                  </div>

                  {/* TicketNumber */}
                  <div className="SqlTicketNumber">
                    <div className="errorMessage">
                      <ErrorMessage
                        errors={errors}
                        name="ticketNumber"
                      ></ErrorMessage>
                    </div>
                    <Field
                      label="Ticket Number"
                      hint="NOTE: If not provided an ADO ticket will be created in Azure DevOps"
                    >
                      <Controller
                        name="ticketNumber"
                        control={control}
                        render={({ field }) => <Input {...field} />}
                      />
                    </Field>
                  </div>

                  {/* Status */}
                  {editing && (
                    <div className="SqlStatus">
                      <div className="errorMessage">
                        <ErrorMessage errors={errors} name="status" />
                      </div>
                      <SelectDropDownData
                        label="Status"
                        options={sqlStatus}
                        control={control}
                        name="status"
                      />
                    </div>
                  )}

                  {/* Business Unit */}
                  <div className="SqlBusinessUnit">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="businessUnit" />
                    </div>
                    <SelectDropDownData
                      label="Business Unit"
                      options={sqlBusiness}
                      control={control}
                      name="businessUnit"
                    />
                  </div>

                  {/* Attachments */}
                  <div className="attachmentsDiv">
                    <h2>Attachments</h2>
                    <FileUploaderCommon
                      reload={reloadFiles}
                      attachment={
                        reloadFiles
                          ? item?.fields._attachments.value
                          : itemDefinition?.fields._attachments.value
                      }
                      listDefinition={list!}
                      filesOnForm={handleFiles}
                      fileTypes={["txt", "sql"]}
                    />
                  </div>
                </div>
              )}
              <div className="submitbutton" style={{ marginTop: "15px" }}>
                {beingSubmitted === false ? (
                  <Button
                    // disabled={!isValid}
                    appearance="primary"
                    type="submit"
                  >
                    Save Request
                  </Button>
                ) : (
                  <div style={{ marginTop: "10px" }}>
                    <Field
                      validationMessage="Please Wait... Saving and Uploading Files."
                      validationState="none"
                    >
                      <ProgressBar />
                    </Field>
                  </div>
                )}
              </div>
            </form>
          ) : (
            <div></div>
          )}
        </DrawerBody>
      </OverlayDrawer>
    </div>
  );
};
