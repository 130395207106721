import React from "react";
import config from "./sample/lib/config";
/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */
class TermsOfUse extends React.Component {
  render() {
    return (
      <div>
        <h1>Terms of Use</h1>
        <h1>Endpoint: {config.apiEndpoint}</h1>
        <h1>apiName: {config.apiName}</h1>
        <h1>clientId: {config.clientId}</h1>
        <h1>initiateLoginEndpoint: {config.initiateLoginEndpoint}</h1>
      </div>
    );
  }
}

export default TermsOfUse;
