
export type DropDownData = {
    label: string,
    value: string
}

export const dropDownToString = (value: string | DropDownData) => {
    if (value !== null && value !== undefined) {
        if (typeof value === "string") {
            return value;
        }
        const currentValue: DropDownData = value;
        return currentValue.label
    }
    return "";
}