import { ContentHubItemElement } from "../../../../Services/types/ContentHub/ContentHubItemElement";
import { ContentHubListsElement } from "../../../../Services/types/ContentHub/ContentHubListsElement";
import { ContentHubSiteElement } from "../../../../Services/types/ContentHub/ContentHubSiteElement";
import { BalanceTransfer } from "../../../../Services/types/ContentHub/Credlt/BalanceTransfer";
import { ContentHubUser } from "../../../../Services/types/General/user";
import { ContentHubListRegistry } from "../../SitesRegistry";
import { FormBalanceTransfer } from "./BalanceTransferForm";

export const BalanceTransferListRegistry: ContentHubListRegistry = {
  name: "BalanceTransfer",
  header: "Balance Transfer",
  columns: [
    { key: "id", displayName: "ID", visible: false },
    { key: "counterpartyFrom", displayName: "Counterparty (From)" },
    { key: "accountNumberFrom", displayName: "Account Number (From)" },
    { key: "counterpartyTo", displayName: "Counterparty (To)" },
    { key: "accountNumberTo", displayName: "Account Number (To)" },
    { key: "status", displayName: "Status" },
    { key: "requestor", displayName: "Requestor" },
  ],
  mapper: function (item: ContentHubItemElement<BalanceTransfer>) {
    const requestor = item.fields.requestor.value as ContentHubUser;
    return {
      id: item.id,
      counterpartyFrom: item.fields.counterpartyFrom.value,
      accountNumberFrom: item.fields.accountNumberFrom.value,
      transactionAmountFrom: item.fields.transactionAmountFrom.value,
      counterpartyTo: item.fields.counterpartyTo.value,
      accountNumberTo: item.fields.accountNumberTo.value,
      transactionAmountTo: item.fields.transactionAmountTo.value,
      status: item.fields.status.value,
      requestor: requestor.name,
    };
  },
  renderForm: function (
    siteDefinition: ContentHubSiteElement,
    listDefinition: ContentHubListsElement,
    selectedItem: ContentHubItemElement<BalanceTransfer>,
    drawerOpen: boolean,
    onDrawerClosed: (
      result: BalanceTransfer | null,
      changesApplied: boolean
    ) => void
  ): JSX.Element {
    return (
      <FormBalanceTransfer
        site={siteDefinition}
        list={listDefinition}
        itemDefinition={selectedItem}
        drawerOpen={drawerOpen!}
        onDrawerClosed={onDrawerClosed}
      />
    );
  },
};
