import * as React from "react";
import { DismissRegular } from "@fluentui/react-icons";
import {
    MessageBar,
    MessageBarActions,
    MessageBarTitle,
    MessageBarBody,
    MessageBarGroup,
    MessageBarIntent,
    Button,
    makeStyles,
    tokens,
} from "@fluentui/react-components";
import { timer } from 'rxjs';

export interface MessageAlert {
    title: string,
    body: string,
    intent: MessageBarIntent,
    id: number
}

const useStyles = makeStyles({
    messageBarGroup: {
        padding: tokens.spacingHorizontalMNudge,
        display: "flex",
        flexDirection: "column",
        marginTop: "10px",
        gap: "10px",
    },
    buttonGroup: {
        display: "flex",
        justifyContent: "end",
        gap: "5px",
    },
});

export const MessageBarCommon = ({
    messageAlerts,
    dismissMessages
}: {
    messageAlerts: MessageAlert[],
    dismissMessages: (alerts: MessageAlert[]) => void
}) => {
    const styles = useStyles();
    React.useEffect(() => {
        if (messageAlerts.length > 0) {
            timer(10000).subscribe(() => {
                clearMessages();
            })
        }
    }, messageAlerts)

    const dismissMessage = (messageId: number) => {
        const dismissed = messageAlerts.filter((alert) => alert.id === messageId);
        dismissMessages(dismissed);
    }

    const clearMessages = () => {
        dismissMessages(messageAlerts);
    }

    return (
        <div>
            <MessageBarGroup animate="both" className={styles.messageBarGroup}>
                {messageAlerts.map(({ intent, id, title, body }) => (
                    <MessageBar key={`${intent}-${id}`} intent={intent}>
                        <MessageBarBody>
                            <MessageBarTitle>{title}</MessageBarTitle>
                            {body}
                        </MessageBarBody>
                        <MessageBarActions
                            containerAction={
                                <Button
                                    onClick={() => dismissMessage(id)}
                                    aria-label="dismiss"
                                    appearance="transparent"
                                    icon={<DismissRegular />}
                                />
                            }
                        />
                    </MessageBar>
                ))}
            </MessageBarGroup>
        </div>
    );
};