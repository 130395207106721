import {
  Avatar,
  Badge,
  Body1Stronger,
  Button,
  Caption1,
  Card,
  CardHeader,
  Link,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableRow,
  Textarea,
  TextareaProps,
  Field,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";
import { useContext, useState } from "react";
import * as ApprovalService from "../../../Services/approvals/ApprovalService";
import {
  ApprovalStatusToString,
  ApprovalRequest,
  ApprovalStatus,
} from "../../../Services/types/approvalRequest";
import { ApprovalUpdateRequest } from "../../../Services/types/approvalUpdateRequest";
import { formatDate, getStatusBadgeColor } from "../../../utils/utils";
import { TeamsFxContext } from "../../Context";
import { ApprovalHistory } from "../ApprovalHistory/ApprovalHistory";
import "./ApprovalDrawer.css";
import { pages } from "@microsoft/teams-js";
import config from "../../sample/lib/config";
import { ApprovalHistoryState } from "../../../Services/types/approvalHistoryElement";

export const ApprovalCard = ({
  request,
  history,
  userEmail,
  onUpdate,
  collapsed = false,
  showLinks = true,
  showHistory = true,
  showParameters = true,
  showRelatedHistory = true,
  showSource = true,
}: {
  request: ApprovalRequest | null;
  history?: ApprovalHistoryState[];
  userEmail: string;
  onUpdate?: (response: ApprovalRequest) => void;
  collapsed?: boolean;
  showLinks?: boolean;
  showHistory?: boolean;
  showParameters?: boolean;
  showRelatedHistory?: boolean;
  showSource?: boolean;
}) => {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const [updateErrorFlag, setUpdateErrorFlag] = useState<boolean>(false);
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");

  const IsApprover = () => {
    if (request?.approvers.find((x) => x.email === userEmail) !== undefined) {
      return true;
    }
    return false;
  };
  const canEdit = request?.status === ApprovalStatus.Pending && IsApprover();

  const [commentValidation, setCommentValidation] = useState<string | null>(
    null
  );

  const updateRequest = async (decision: ApprovalStatus) => {
    if (!teamsUserCredential) {
      throw new Error("TeamsFx SDK is not initialized.");
    }
    if (!request) return;
    setCommentValidation(null);
    const updateRequest: ApprovalUpdateRequest = {
      requestId: request?.id,
      approval: decision,
      comment: comment,
    };
    setIsWorking(true);
    try {
      const response = await ApprovalService.updateApproval(
        updateRequest,
        teamsUserCredential
      );
      onUpdate?.(response);
    } catch {
      setUpdateErrorFlag(true);
    }
    setIsWorking(false);
  };

  const requestDetailsRenderer: {
    name: string;
    value: (request: ApprovalRequest) => JSX.Element;
  }[] = [
    ...(showSource
      ? [
          {
            name: "Source",
            value: (r: ApprovalRequest) => <>{r.adapter.name}</>,
          },
          {
            name: "Workflow",
            value: (r: ApprovalRequest) => <>{r.sourceWorkflow}</>,
          },
        ]
      : []),
    {
      name: "Requestor",
      value: (r) => (
        <TableCellLayout
          media={
            <Avatar
              aria-label={r.requestor.email}
              name={r.requestor.name}
              badge={{}}
            ></Avatar>
          }
        >
          {r.requestor.name}
        </TableCellLayout>
      ),
    },
    {
      name: "Approvers",
      value: (r) => (
        <Table size="extra-small">
          <TableBody>
            {r.approvers.map((approver) => (
              <TableRow key={approver.email}>
                <TableCell style={{ paddingLeft: "0" }}>
                  <TableCellLayout
                    media={
                      <Avatar
                        aria-label={approver.email}
                        name={approver.name}
                        badge={{}}
                      ></Avatar>
                    }
                  >
                    {approver.name}
                  </TableCellLayout>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ),
    },
  ];

  const triggerApprove = () => updateRequest(ApprovalStatus.Approved);
  const triggerDeny = () => updateRequest(ApprovalStatus.Denied);
  const triggerRevisionRequired = () => {
    if (comment.trim().length === 0) {
      setCommentValidation("This field is required.");
      return;
    }
    updateRequest(ApprovalStatus.RevisionRequired);
  };

  const updateComment: TextareaProps["onChange"] = (ev, data) => {
    setComment(data.value);
  };

  const goToSiteApp = (siteUri: string) => {
    console.log(config);

    const navigationInfo: pages.currentApp.NavigateWithinAppParams = {
      pageId: "workflows",
      subPageId: siteUri,
    };
    pages.currentApp.navigateTo(navigationInfo);
  };

  const [collapseStatus, setCollapseStatus] = useState<boolean>(collapsed);
  return (
    <>
      {request != null && (
        <>
          {updateErrorFlag && (
            <MessageBar intent="error">
              <MessageBarBody>
                <MessageBarTitle>Error</MessageBarTitle>
                Could not update the request status.
              </MessageBarBody>
            </MessageBar>
          )}

          <Card>
            <Accordion
              collapsible
              defaultOpenItems={collapsed ? undefined : [0]}
              onToggle={(_evt, data) =>
                setCollapseStatus(data.openItems.length == 0)
              }
            >
              <AccordionItem value={0}>
                <AccordionHeader>
                  <CardHeader
                    header={<Body1Stronger>{request.title}</Body1Stronger>}
                    description={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 5,
                        }}
                      >
                        <Caption1>
                          Submitted on {formatDate(request.createdDate)}
                        </Caption1>
                        {!canEdit && (
                          <>
                            <Caption1>
                              <Badge
                                appearance="filled"
                                color={getStatusBadgeColor(request.status)}
                              >
                                {ApprovalStatusToString(request.status)}
                              </Badge>
                              {request.status !== ApprovalStatus.Pending
                                ? ` on ${formatDate(request.modifiedDate!)}`
                                : undefined}
                            </Caption1>
                            {collapseStatus && !canEdit && (
                              <>
                                {request.comment && (
                                  <>
                                    <Body1Stronger style={{ marginTop: 10 }}>
                                      Comment
                                    </Body1Stronger>
                                    <blockquote style={{ margin: "0 10" }}>
                                      {request.comment}
                                    </blockquote>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    }
                  />
                </AccordionHeader>
                <AccordionPanel>
                  <Table
                    size="extra-small"
                    className="requestDetailsTable"
                    style={{ marginTop: 10 }}
                  >
                    <TableBody>
                      {requestDetailsRenderer.map((r) => (
                        <TableRow key={r.name}>
                          <TableCell>{r.name}</TableCell>
                          <TableCell>{r.value(request)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <div className="approvalDrawerControls">
                    {canEdit && (
                      <>
                        <div className="approvalDrawerComment">
                          <Field
                            label="Comments"
                            validationMessage={commentValidation ?? undefined}
                          >
                            <Textarea
                              size="medium"
                              value={comment}
                              onChange={updateComment}
                            ></Textarea>
                          </Field>
                        </div>
                        <div className="approvalDrawerButtons">
                          {!isWorking && (
                            <>
                              <Button
                                appearance="primary"
                                onClick={triggerApprove}
                              >
                                Approve
                              </Button>
                              <Button
                                appearance="secondary"
                                onClick={triggerDeny}
                              >
                                Deny
                              </Button>
                              {request.updates?.findIndex(
                                (a) =>
                                  a.status == ApprovalStatus.RevisionRequired
                              ) !== -1 && (
                                <Button
                                  appearance="secondary"
                                  onClick={triggerRevisionRequired}
                                >
                                  Revision Required
                                </Button>
                              )}
                            </>
                          )}
                          {isWorking && (
                            <Spinner
                              size="extra-tiny"
                              label="Saving changes"
                              style={{ padding: "6px 0" }}
                            />
                          )}
                        </div>
                      </>
                    )}
                    {!canEdit && (
                      <>
                        {request.comment && (
                          <>
                            <Body1Stronger style={{ marginTop: 10 }}>
                              Comment
                            </Body1Stronger>
                            <blockquote style={{ margin: "0 10" }}>
                              {request.comment}
                            </blockquote>
                          </>
                        )}
                      </>
                    )}
                    {showLinks &&
                      request.links?.length &&
                      request.links!.map((link, index) => (
                        <div key={index}>
                          {link.url.indexOf("http") <= -1 ? (
                            <div>
                              <Button onClick={() => goToSiteApp(link.url)}>
                                {link.text}
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Link target="_blank" href={link.url} key={index}>
                                {link.text}
                              </Link>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                  {showParameters && (
                    <>
                      <Body1Stronger style={{ marginTop: 10 }}>
                        Request parameters
                      </Body1Stronger>
                      <Table size="extra-small">
                        <TableBody>
                          {Object.entries(request.parameters).map(
                            ([key, value]) => (
                              <TableRow key={key}>
                                <TableCell>
                                  {key[0].toUpperCase() + key.slice(1)}
                                </TableCell>
                                <TableCell>{value}</TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </>
                  )}
                  {showHistory && (
                    <>
                      <Body1Stronger>History</Body1Stronger>
                      <ApprovalHistory
                        requestId={showRelatedHistory ? request.id : undefined}
                        correlationKey={request.correlationKey as string}
                        history={history}
                      />
                    </>
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Card>
        </>
      )}
    </>
  );
};
