import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { ContentHubSiteElement } from "../Services/types/ContentHub/ContentHubSiteElement";
import { ContentHubListsElement } from "../Services/types/ContentHub/ContentHubListsElement";
import { TeamsFxContext } from "../components/Context";
import * as ContentHubService from "../Services/contentHub/ContentHubService";
import * as axios from "axios";
import { ContentHubQueryPagination } from "../Services/types/ContentHub/ContentHubQueryPagination";
import { ContentHubPaginatedCollection } from "../Services/types/ContentHub/ContentHubPaginatedCollection";

export const ContentHubContext = createContext<{
  sites: ContentHubSiteElement[];
  lists: Record<string, ContentHubListsElement[]>;
  permissions: string[];
  isLoading: boolean;
  error?: Error;
}>({ sites: [], lists: {}, permissions: [], isLoading: true });

export const ContentHubContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [contentHubSites, setSites] = useState<ContentHubSiteElement[]>([]);
  const [contentHubLists, setLists] = useState<
    Record<string, ContentHubListsElement[]>
  >({});
  const [contentHubPermissions, setPermissions] = useState<string[]>([]);
  useEffect(() => {
    try {
      setIsLoading(true);
      if (!teamsUserCredential) {
        throw new Error("User is not authenticated");
      }
      (async () => {
        try {
          const permissions = await ContentHubService.getPermissions(
            teamsUserCredential
          );

          let sites: ContentHubSiteElement[] = [];
          let sitesContinuationString: string | null = null;
          do {
            const sitesResponse: ContentHubPaginatedCollection<ContentHubSiteElement> =
              await ContentHubService.getSites(
                {
                  ContinuationString: sitesContinuationString,
                } as ContentHubQueryPagination,
                teamsUserCredential
              );
            sites = sites.concat(sitesResponse.items);
            sitesContinuationString = sitesResponse.continuationString;
          } while (sitesContinuationString);

          const lists: Record<string, ContentHubListsElement[]> = {};
          let listsContinuationString: string | null = null;
          for (const site of sites) {
            lists[site.id] = [];
            do {
              const listsResponse: ContentHubPaginatedCollection<ContentHubListsElement> =
                await ContentHubService.getLists(
                  site.id,
                  { ContinuationString: listsContinuationString ?? undefined },
                  teamsUserCredential
                );
              lists[site.id] = lists[site.id].concat(listsResponse.items);
              listsContinuationString = listsResponse.continuationString;
            } while (listsContinuationString);
          }

          setPermissions(permissions.items);
          setSites(sites);
          setLists(lists);
          setError(undefined);
        } catch (err) {
          if (axios.default.isAxiosError(err)) {
            let funcErrorMsg = "";

            if (err?.code === "ERR_NETWORK") {
              funcErrorMsg = "Unable to retrieve sites and lists.";
            } else {
              funcErrorMsg = err.message;
              if (err.response?.data?.error) {
                funcErrorMsg += ": " + err.response.data.error;
              }
            }

            throw new Error(funcErrorMsg);
          }
          throw err;
        } finally {
          setIsLoading(false);
        }
      })();
    } catch (err) {
      setSites([]);
      setLists({});
      setError(err as Error);
      setIsLoading(false);
    }
  }, [teamsUserCredential]);
  return (
    <ContentHubContext.Provider
      value={{
        sites: contentHubSites,
        lists: contentHubLists,
        isLoading: isLoading,
        permissions: contentHubPermissions,
        error: error,
      }}
    >
      {children}
    </ContentHubContext.Provider>
  );
};
