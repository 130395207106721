import {
  Dropdown,
  Label,
  Option,
  OptionOnSelectData,
  SelectionEvents,
} from "@fluentui/react-components";
import {
  ApprovalStatus,
  ApprovalStatusToString,
} from "../../../Services/types/approvalRequest";

export const ApprovalStatusDropDownComponent = ({
  onSelectionChange,
  defaultStatus,
}: {
  onSelectionChange: (selected: ApprovalStatus | null) => void;
  defaultStatus: ApprovalStatus;
}) => {
  const options = Object.values(ApprovalStatus).filter(
    (v) => typeof v === "string" && v !== "Cancelled" && v !== "New"
  );
  const triggerSelectionChange = (
    _event: SelectionEvents,
    data: OptionOnSelectData
  ) => {
    if (data.optionValue == null) return onSelectionChange(null);
    onSelectionChange(
      ApprovalStatus[
        data.optionValue as keyof typeof ApprovalStatus
      ] as ApprovalStatus
    );
  };
  return (
    <div>
      <Label style={{ display: "block", marginBottom: "8px" }}> Status</Label>
      <Dropdown
        id="dropdownStatus"
        placeholder="Select Status"
        appearance="underline"
        defaultValue={typeof defaultStatus!=='string'?ApprovalStatus[defaultStatus]:defaultStatus}
        onOptionSelect={triggerSelectionChange}
      >
        {options.map((option) => (
          <Option key={option} value={option as string}>{ApprovalStatusToString(option as ApprovalStatus)}</Option>
        ))}
      </Dropdown>
    </div>
  );
};
