import { ContentHubItemElement } from "../../../../Services/types/ContentHub/ContentHubItemElement";
import { ContentHubListsElement } from "../../../../Services/types/ContentHub/ContentHubListsElement";
import { ContentHubSiteElement } from "../../../../Services/types/ContentHub/ContentHubSiteElement";
import { SqlQueryRequest } from "../../../../Services/types/ContentHub/IT/SqlQueryRequests";
import { ContentHubUser } from "../../../../Services/types/General/user";
import { formatDate } from "../../../../utils/utils";
import { ContentHubListRegistry } from "../../SitesRegistry";
import { SqlQueryRequestForm } from "./SqlQueryRequestForm";

export const SqlQueryRegistry: ContentHubListRegistry = {
  name: "SqlQueryRequests",
  header: "SQL Query Request",
  columns: [
    { key: "createdDate", displayName: "Created" },
    { key: "id", displayName: "ID", visible: false },
    { key: "title", displayName: "Title" },
    { key: "sourceServer", displayName: "Source Server" },
    { key: "targetServer", displayName: "Target Server" },
    { key: "database", displayName: "Database" },
    { key: "status", displayName: "Status" },
    { key: "requestor", displayName: "Requestor" },
  ],
  mapper: function (item: ContentHubItemElement<SqlQueryRequest>) {
    const requestor = item.fields.requestor.value as ContentHubUser;
    return {
      createdDate: formatDate(new Date(item.createdDate)),
      id: item.id,
      title: item.fields.title.value,
      sourceServer: item.fields.sourceServer.value,
      targetServer: item.fields.targetServer.value,
      database: item.fields.database.value,
      status: item.fields.status.value,
      requestor: requestor.name,
    };
  },
  renderForm: function (
    siteDefinition: ContentHubSiteElement,
    listDefinition: ContentHubListsElement,
    selectedItem: ContentHubItemElement<SqlQueryRequest>,
    drawerOpen: boolean,
    onDrawerClosed: (result: SqlQueryRequest | null, changesApplied: boolean) => void
  ): JSX.Element {
    return (
      <SqlQueryRequestForm
        site={siteDefinition}
        list={listDefinition}
        itemDefinition={selectedItem}
        drawerOpen={drawerOpen!}
        onDrawerClosed={onDrawerClosed}
      />
    );
  },
};
