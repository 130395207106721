import { app } from "@microsoft/teams-js";
import { useState, useEffect } from "react";

export default function useAppContextDeepLinks() {
  const [deepLink, setDeepLink] = useState<string | null>(null);
  useEffect(() => {
    (async () => {
      const context = await app.getContext();
      const urlPayload = context.page.subPageId;
      console.log(urlPayload);
      if (urlPayload !== undefined && urlPayload !== "") {
        try {
          const payload = JSON.parse(JSON.stringify(urlPayload));
          setDeepLink(payload);
        } catch (error) {
          console.log(error);
          throw error;
        }
      }
    })();
  }, []);
  return [deepLink, setDeepLink];
}
