import { ItemTest, ContentHubItemElement } from "./ContentHubItemElement";
import { IContentHubSearchFilter } from "./ContentHubSearchFilter";
import { PropertySort } from "./PropertySort";

export enum FilterModes {
  Any = "Any",
  All = "All",
}

export interface SearchItemsRequest<T = unknown> {
  fieldFilters: Record<keyof ItemTest<T>, IContentHubSearchFilter>;
  fieldFiltersMode: FilterModes;
  itemFilters:
    | Record<
        keyof Omit<ContentHubItemElement, "fields">,
        IContentHubSearchFilter
      >
    | object;
  itemFiltersMode: FilterModes;
  sortingOptions: PropertySort[];
}


